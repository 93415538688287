.mobileBar {
  align-items: center;
  background: #fff;
  bottom: -59px;
  box-shadow: 0 -1px 2px 0 rgba(42, 43, 48, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 2000;
}

.investButton {
  margin-right: 70px;
}

.visible {
  @media (max-width: 1199px) {
    bottom: 0;
    visibility: visible;
  }
}
