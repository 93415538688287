.noOfferings {
  margin: 80px auto;
  max-width: 100%;
  text-align: center;
  width: 450px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin: 20px 0 40px;
  }

  .clearButton {
    margin: 0 auto;
    max-width: 100%;
    width: 220px;

    svg {
      margin-right: 8px;
    }
  }
}
