.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  padding: 20px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}
