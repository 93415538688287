@use 'scss/base/variables' as *;

.images {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  img {
    background: get($gray, 200);
    border-radius: 100%;
    max-width: calc(33% - 13px);
    width: 110px;

    @media (max-width: 991px) {
      max-width: calc(33% - 6px);
      width: 80px;
    }

    &:not(:last-of-type) {
      margin-right: 20px;

      @media (max-width: 991px) {
        margin-right: 10px;
      }
    }
  }
}
