.articles {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
}

.articleCard {
  width: calc(33.3333% - 20px);

  @media (max-width: 991px) {
    width: calc(50% - 15px);
  }

  @media (max-width: 575px) {
    width: auto;
  }

  h3 {
    margin-top: 20px;
  }
}
