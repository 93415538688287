@use 'scss/base/variables' as *;

.wrap {
  background: get($gray, 100);
  border-radius: $card-border-radius;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: 760px;
  padding: 24px;

  @media (max-width: 991px) {
    display: block;
    height: auto;
    margin-bottom: 40px;
    padding-top: 12px;
  }

  @media (max-width: 575px) {
    display: flex;
  }
}

.top {
  align-items: center;
  display: flex;
  gap: 24px;
  height: 140px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  @media (max-width: 575px) {
    height: auto;
    margin-bottom: 0;
    width: 60px;
  }
}

.topText {
  @media (max-width: 575px) {
    display: none;
  }

  .account {
    color: get($gray, 600);
    font-weight: 700;
    margin-bottom: 3px;
  }

  .inProgress {
    font-size: 1.6rem;
    font-weight: 700;

    &::after {
      background: get($summit, 200);
      content: '';
      display: block;
      height: 1px;
      margin: 8px 0;
      width: 14px;
    }
  }

  .description {
    font-size: 1.2rem;
  }
}

.circle {
  height: 140px;
  position: relative;
  width: 140px;

  @media (max-width: 1439px) {
    height: 110px;
    width: 110px;
  }

  @media (max-width: 575px) {
    height: 60px;
    width: 60px;
  }

  svg {
    height: 140px;
    transform: rotate(-90deg);
    width: 140px;

    @media (max-width: 1439px) {
      height: 110px;
      width: 110px;
    }

    @media (max-width: 575px) {
      height: 60px;
      width: 60px;
    }
  }
}

.percentage {
  font-size: 3.2rem;
  font-weight: 700;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 575px) {
    font-size: 1.6rem;
  }
}

.progressTrack {
  fill: transparent;
  stroke: get($summit, 200);
  stroke-width: 1;
}

.progress {
  fill: transparent;
  stroke-dasharray: 100;
  stroke-linecap: round;
  stroke-width: 2;
}

.steps {
  @media (max-width: 575px) {
    flex: 0 0 calc(100% - 60px);
  }
}

.step {
  margin-bottom: 35px;

  @media (max-width: 991px) {
    display: none;
    margin-bottom: 0;
    padding-left: 24px;
  }

  &.activeStep {
    @media (max-width: 991px) {
      display: block;
    }
  }
}

.stepTitle {
  font-weight: 700;
  margin-bottom: 5px;
}

.stepDescription {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.stepProgressTrack {
  background: get($summit, 200);
  border-radius: 2px;
  height: 8px;

  @media (max-width: 575px) {
    height: 4px;
  }
}

.stepProgress {
  background: get($midnight, 500);
  border-radius: 2px;
  height: 100%;
  width: 0;
}

.stepProgressComplete {
  background: get($green, 600);
}

.button {
  margin-top: auto;
  width: 100%;

  @media (max-width: 991px) {
    display: none;
  }
}

.invest {
  align-items: end;
  background: #fff;
  display: grid;
  grid-column-gap: 56px;
  grid-template-columns: 320px auto;
  grid-template-rows: auto;
  height: auto;
  margin-bottom: 12px;

  @media (max-width: 1199px) {
    grid-template-columns: 300px auto;
  }

  @media (max-width: 767px) {
    display: block;
  }

  .top {
    margin-bottom: 20px;

    @media (max-width: 991px) {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      width: auto;
    }
  }

  .topText {
    @media (max-width: 575px) {
      display: block;
    }
  }

  .steps {
    display: flex;
    gap: 28px;
    margin-bottom: 50px;
    margin-bottom: 0;
    padding-bottom: 15px;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .step {
    margin-bottom: 0;
  }

  .button {
    @media (max-width: 991px) {
      display: flex;
      margin-bottom: 16px;
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}

.notAccredited {
  .profileStep,
  .accountStep {
    opacity: 0.6;
  }

  .inProgress {
    color: get($red, 600);
  }
}
