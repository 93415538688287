.rowSeparator {
  margin: 15px 0 30px;

  @media (max-width: 767px) {
    display: none;
  }
}

.buttonWrap {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 767px) {
    display: block;
  }

  p {
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  button {
    flex-shrink: 0;
  }
}
