@use 'scss/base/variables' as *;

.component {
  margin: 45px 0;
  position: relative;
}

.learnMoreLink {
  width: 100%;
}

.sectionText {
  color: get($gray, 900);
  font-size: 1.6rem;
  line-height: 1.875;

  p {
    font-size: 1.6rem;
    word-break: break-word;
  }

  img {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  ul {
    list-style: none;
    padding-left: 15px;

    li {
      margin-bottom: 15px;

      &::before {
        color: get($summit, 900);
        content: '• ';
        left: 0;
        padding-right: 10px;
        position: absolute;
      }
    }
  }

  ol li {
    margin-bottom: 15px;
  }
}
