@use 'scss/base/variables' as *;

.negativeCashflow {
  color: get($summit, 700);
}

.positiveCashflow {
  color: #299e23;

  &::before {
    content: '+';
  }
}
