@use 'scss/base/variables' as *;

.headerNav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: -60px;
  padding: 10px 15px;
}

.signinLink {
  font-weight: 500;
}

.defaultLogo {
  height: 40px;
  margin-right: 60px;

  @media (max-width: 1199px) {
    display: none;
  }

  img {
    margin-top: -5px;
    width: 210px;
  }
}

.smallLogo {
  display: none;
  margin: 0 20px 0 0;

  @media (max-width: 1199px) {
    display: block;
  }
}
