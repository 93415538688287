@use 'scss/base/variables' as *;

.card {
  padding: 0;
}

.top {
  padding: 20px 20px 0;
}

.titleWrap {
  display: flex;
  justify-content: space-between;

  @media (max-width: 479px) {
    flex-direction: column;
    gap: 15px;
  }

  button {
    max-height: 36px;
    max-width: 100%;
    width: 180px;
  }
}

.subheading {
  color: get($midnight, 200);
  font-size: 1.2rem;
  font-weight: 700;
}

.accountTab {
  align-items: center;
  display: flex;
  gap: 12px;
  text-align: left;

  > span:last-child {
    padding: 4px 8px;

    svg path {
      fill: #fff;
    }
  }
}

.accountDescription {
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
}

.modalSubmitButton {
  min-width: 240px;
}

.resourceCards {
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  a {
    width: 33.3333%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  br {
    @media (max-width: 1199px) {
      display: none;
    }
  }
}
