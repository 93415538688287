.textContent {
  background-image: url('../../../../images/icons/warning.svg');
  background-position: left top 5px;
  background-repeat: no-repeat;
  padding-left: 35px;

  &.accreditationPending {
    background-image: url('../../../../images/icons/check-circle-light.svg');
  }
}
