.offering {
  background: #fff;
  margin: -60px 0;
  min-height: 920px;
  padding-bottom: 60px;
}

.offeringContent {
  margin-top: 30px;
}

.sideNavColumn {
  @media (max-width: 1199px) {
    display: none;
  }
}
