.card {
  margin-top: auto;
}

.confidentialityAgreementBlock {
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 20px;
  max-height: 350px;
  overflow: auto;
  padding: 15px 30px;
}

.submitButton {
  width: 140px;
}

.signatureBox {
  background: #def6ff;
  border-radius: 3px;
  margin: auto -30px -15px;
  margin-bottom: 20px;
  padding: 16px 30px;

  p {
    font-weight: 500;
  }
}

.backLink {
  float: right;
  margin: 12px 40px 0 0;

  @media (max-width: 479px) {
    clear: both;
    margin: 15px 0 0;
  }
}
