@use 'scss/base/variables' as *;

.heading {
  margin: 0 0 50px;
}

.loadingButton {
  float: right;
  height: 50px;
  width: 150px;
}

.submitButton {
  float: right;
  margin-top: 30px;
  min-width: 150px;
}

.radioButton {
  align-items: center;
  border: 2px solid;
  border-radius: 8px;
  display: flex;
  height: 70px;
  margin-bottom: 20px;
  padding: 18px;
  width: 100%;

  label {
    padding-left: 0;

    &::before {
      margin-right: 20px;
      position: relative;
    }
  }

  &.optIn {
    border-color: get($gray, 300);
    font-size: 1.8rem;

    &.selected {
      background: #eefaee;
      border-color: #b3e2ae;
    }
  }

  &.optOut {
    border-color: #efefef;
    font-size: 1.2rem;

    label {
      color: get($gray, 500);
    }
  }
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  @media (max-width: 576px) {
    align-items: flex-end;
    flex-flow: wrap;
    flex-direction: column-reverse;
  }

  a,
  button {
    width: 180px;
  }

  a {
    margin-left: 30px;

    @media (max-width: 576px) {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }
}
