@use 'scss/base/variables' as *;

.info {
  padding: 0 40px 30px;

  @media (max-width: 1400px) {
    border-top: 1px solid get($gray, 200);
    margin-top: 20px;
  }

  @media (max-width: 575px) {
    padding: 0 0 30px;
  }
}

.increaseAllocation {
  padding: 0 40px;

  @media (max-width: 575px) {
    padding: 0;
  }

  h3 {
    margin-top: 0;
  }
}
