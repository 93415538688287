.downloadBar {
  background: #fff;
  bottom: 0;
  box-shadow: 0 -1px 2px 0 rgba(42, 43, 48, 0.15);
  left: 0;
  padding: 10px 0;
  position: fixed;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 1;
}

.flexWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > span {
    display: none !important;
  }

  i {
    margin-right: 10px;
  }
}

.selectedText {
  line-height: 1.3;
  padding-right: 20px;

  @media (max-width: 576px) {
    display: block;
  }
}

.selectDownloadDocument {
  width: 125px;

  @media (max-width: 1300px) {
    margin-right: 40px;
  }

  @media (max-width: 600px) {
    margin-right: 65px;
  }
}
