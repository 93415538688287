@use 'scss/base/variables' as *;

.wrapper {
  align-items: flex-start;
  border: 1px solid get($gray, 200);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  margin: 20px 0;

  label {
    margin: 12px 0;
  }

  hr {
    color: get($gray, 200);
    margin: 10px auto;
    width: 100%;
  }

  &.selected {
    border-color: get($summit, 900);
  }

  &.invalid {
    border-color: get($red, 600);
    box-shadow: 0 0 3px get($red, 600);
  }
}

.rowContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  padding: 16px;
  text-align: left;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.iconWrapper {
  align-items: center;
  background-color: get($gray, 100);
  border-radius: 8px;
  display: flex;
  height: 36px;
  padding: 6px;
  width: 36px;
}

.secondaryText {
  color: get($midnight, 200);
  font-size: 1.2rem;
}

.bankAccountSelectWrapper {
  padding: 16px;
  width: 100%;
}

.bankAccountSelect {
  margin: 0;

  label {
    margin: 0;
  }
}
