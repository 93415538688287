@use 'scss/base/variables' as *;

.overview {
  margin: -60px 0 0 !important;
  padding: 20px 0;

  @media (max-width: 991px) {
    padding-bottom: 5px;
  }

  @media (max-width: 575px) {
    padding: 20px 5px;
  }
}

.heading6 {
  color: get($gray, 500);
  font-weight: 500;
  margin: 0;
}

.top {
  display: flex;
  justify-content: space-between;

  @media (max-width: 575px) {
    display: block;
  }

  .heading {
    align-items: center;
    display: flex;
    margin: 15px 0 0;

    @media (max-width: 576px) {
      display: block;
    }

    span[aria-busy='true'] {
      width: 100%;
    }
  }
}

.closingStageLabel {
  font-size: 1.2rem;
  margin-left: 15px;

  @media (max-width: 576px) {
    margin: 15px 0 0;
  }
}

.left.loading {
  width: 50%;

  @media (max-width: 575px) {
    width: 100%;
  }
}

.right {
  @media (max-width: 575px) {
    margin-top: 30px;
  }

  .downloadButton {
    margin-bottom: 15px;

    @media (max-width: 575px) {
      display: block;
      width: 143px;
    }

    i {
      margin-right: 10px;
    }
  }
}

.headingText {
  @media (max-width: 576px) {
    display: block;
  }
}

.metricsWrap {
  display: flex;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  &.investment {
    margin-top: 40px;

    @media (max-width: 991px) {
      display: block;
    }

    .metric {
      flex: 0 0 21.6666%;

      &.balance {
        flex: 0 0 35%;
      }
    }
  }
}

.metric {
  @media (max-width: 991px) {
    flex-basis: 50%;
    margin: 0 0 15px;
  }

  @media (max-width: 575px) {
    padding-right: 15px;
  }

  &:last-child {
    margin-right: 0;
  }

  &.balance {
    .metricValue {
      font-size: 4.2rem;

      @media (max-width: 575px) {
        font-size: 3.6rem;
      }
    }
  }
}

.metricValue {
  color: get($gray, 900);
  font-size: 2.2rem;

  @media (max-width: 575px) {
    font-size: 1.8rem;
  }

  .positive {
    color: #299e23;

    &::before {
      content: '\f0d8';
      font-family: FontAwesome;
      margin-right: 10px;
    }
  }

  .negative {
    color: get($poppy, 500);
  }
}

.currentColumn,
.exitedColumn {
  .metricsWrap {
    flex-wrap: wrap;

    .metric {
      padding-right: 10px;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 30px;

        @media (max-width: 991px) {
          margin-bottom: 15px;
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        flex-basis: 65%;

        @media (max-width: 1199px) {
          flex-basis: 60%;
        }
      }
    }
  }
}

.currentColumn {
  border-right: 1px solid get($gray, 200);
  padding-right: 60px !important;

  @media (max-width: 1199px) {
    padding-right: 15px !important;
  }

  @media (max-width: 991px) {
    border: 0;
  }
}

.exitedColumn {
  padding-left: 60px !important;

  @media (max-width: 1199px) {
    padding-left: 15px !important;
  }
}
