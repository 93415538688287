@use 'scss/base/variables' as *;

.heading {
  margin: 0 0 40px;
}

.loadingButton {
  float: right;
  height: 50px;
  width: 150px;
}

.backButton {
  margin: auto;
  width: fit-content;
}

.timer {
  display: flex;
  gap: 8px;
  margin-bottom: 30px;
}

.timerGroup {
  border: 1px solid get($summit, 200);
  border-radius: 16px;
  padding: 9px 16px;
  width: 104px;

  > div:first-child {
    font-size: 2rem;
    font-weight: 700;
  }

  > div:last-child {
    font-size: 1.2rem;
  }
}

.timeline {
  background: get($gray, 200);
  border-radius: 10px;
  display: flex;
  height: 40px;
  overflow: hidden;
  position: relative;

  div {
    border-radius: 10px;
    height: 100%;
    position: relative;
  }

  .window {
    background: get($gray, 200);
    display: flex;
    position: relative;
    width: 70%;
    z-index: 2;

    @media (max-width: 767px) {
      width: 60%;
    }
  }

  .windowProgress {
    background: get($midnight, 500);
    border-radius: 10px;
    height: 100%;
    min-width: 10%;
  }

  .timelineSeparator {
    background: #fff;
    border-radius: 20px;
    display: block;
    height: 60px;
    position: absolute;
    right: calc(30% - 8px);
    top: -10px;
    width: 40px;

    @media (max-width: 767px) {
      right: calc(40% - 10px);
    }
  }
}

.timelineDates {
  color: get($gray, 600);
  display: flex;
  margin-top: 10px;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }

  > div {
    &:first-child {
      width: 70%;

      @media (max-width: 767px) {
        width: 60%;
      }
    }

    &:last-child {
      padding-left: 15px;
    }
  }

  span {
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.rowSeparator {
  margin: 15px 0 30px;

  @media (max-width: 767px) {
    display: none;
  }
}
