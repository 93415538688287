.buttonWrap {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  button {
    @media (max-width: 767px) {
      max-width: 100%;
      width: 270px;
    }
  }
}
