@use 'scss/base/variables' as *;

.tableWrap {
  overflow-x: auto;

  table {
    margin-bottom: 20px;
    margin-top: 20px;
    white-space: pre-wrap;
    width: 100%;

    thead {
      th:first-child {
        text-align: left;
      }

      tr:first-child {
        border-bottom: 0;
      }
    }

    tr {
      border-bottom: 1px solid get($gray, 300);
    }

    th {
      color: get($gray, 300);
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 5px;
    }

    td {
      font-size: 1.4rem;
      min-width: 68px;
      padding: 10px 5px;
    }

    p {
      margin: 0;
    }
  }
}

.footer,
.hasFooter table tr:last-child {
  background: get($summit, 100);
  border-bottom: 0;
  color: get($summit, 900);
  font-weight: 700;
}

.hasHeader table tr:first-child td,
.firstColumnIsHeader table td:first-child {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
}
