@use 'scss/base/variables' as *;

.statusNav {
  float: left;
  margin-left: -285px;
  margin-top: 40px;
  width: 239px;

  @media (max-width: 1400px) {
    float: none;
    margin: 0;
    width: auto;
  }
}

.navTitle {
  border-bottom: 1px solid get($gray, 200);
  color: get($gray, 500);
  font-size: 1.6rem;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.step {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  margin-top: 15px;

  &.active {
    font-weight: 700;
  }

  > span {
    flex: 0 0 auto;
    padding-right: 7px;
  }

  img {
    flex-shrink: 0;
    width: 20px;
  }
}

.stepDescription {
  line-height: 1.3;
}

.capital-call-timer {
  max-width: 100%;
  padding-top: 35px;
  width: 250px;

  @media (max-width: 1400px) {
    margin-bottom: 30px;
  }

  .due {
    color: get($gray, 500);
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

.cancelInvestment {
  margin-bottom: 30px;
  margin-top: 15px;
  padding-top: 10px;
  text-align: left;
  width: 100%;
}
