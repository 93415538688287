@use 'scss/base/variables' as *;

.valueBox {
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
  }

  :global(.textLabel) {
    margin: 30px 0 5px;

    @media (max-width: 767px) {
      margin: 20px 0 5px;
    }
  }
}

.submitBox {
  padding: 40px 0;
  text-align: center;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .disclaimerCheckbox {
    margin: 0 auto 30px;

    label {
      display: inline-flex;
    }
  }

  .submitButton {
    margin: 30px auto 0;
    max-width: 100%;
    width: 300px;
  }
}

.errorMessage {
  margin-top: 30px;
}
