.containerSidebar {
  margin-bottom: 40px;
}

.account {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 30px;
  padding-bottom: 30px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.docIcon {
  margin-bottom: 10px;
  width: 70px;
}

.docLink {
  display: block;
}
