@use 'scss/base/variables' as *;

.offeringsList {
  margin: 0 auto;
  max-width: 100%;

  h1 {
    color: get($gray, 900);
    margin: 50px 0 20px;
  }

  h4 {
    margin: 40px 0 10px;

    @media (max-width: 991px) {
      font-size: 1.6rem;
    }
  }

  .subheading {
    color: get($gray, 500);
    font-size: 1.4rem;
    margin-bottom: 25px;

    @media (min-width: 992px) {
      font-weight: 500;
    }
  }
}

.tag {
  color: get($gray, 600);
  font-weight: 500;
  line-height: 1;
}

.cardHeading {
  color: get($gray, 900);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 20px 0 8px;

  @media (max-width: 1199px) {
    font-size: 2.2rem;
  }

  @media (max-width: 767px) {
    font-size: 2rem;
  }
}

.offeringCardRow {
  margin: 0 -7.5px !important;
}

.offeringCardColumn {
  padding: 0 7.5px !important;
}

.cardLoader {
  background: none;
  padding: 60px 0;
  position: relative;
}
