@use 'scss/base/variables' as *;

.postCard {
  max-width: 100%;
}

.postCardTop {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  text-align: left;
  width: 100%;

  @media (max-width: 767px) {
    display: block;
    padding-right: 67px;
  }
}

.titleBlock {
  margin-right: auto;

  &.loading {
    padding-right: 30px;
    width: 100%;
  }
}

.postTitle {
  color: get($moon, 900) !important;
  font-size: 1.8rem;
  line-height: 1.4;
  margin: 0 0 10px;

  &.hasAlert {
    &::after {
      color: get($poppy, 500);
      content: '\f071';
      font-family: FontAwesome;
      line-height: 1.7;
      margin-left: 8px;
    }
  }
}

.date {
  color: get($gray, 500);
  font-size: 1.4rem;
  margin-right: 20px;
  min-width: 150px;
  text-align: right;

  @media (max-width: 767px) {
    margin-top: 5px;
    min-width: 0;
    text-align: left;
  }
}

.collapseButton {
  @media (max-width: 767px) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.postOffering {
  color: get($gray, 500);
  font-size: 1.4rem;
  line-height: 1.4;
}

.postBody {
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2;
  margin-top: 40px;
}

.table {
  margin: 0 0 20px;

  table {
    line-height: 1.5;

    th,
    td {
      border: 1px solid get($gray, 200);
      padding: 8px;
    }
  }
}

.taxDocStep {
  margin-bottom: 20px;
  text-align: center;

  img {
    display: none;
    height: auto;
    max-width: 100%;

    &.desktop {
      @media (min-width: 768px) {
        display: inline-block;
      }
    }

    &.mobile {
      width: 140px;

      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }
}

.image {
  border-radius: 100px;
  height: 50px;
  width: 50px;
}

.postAuthor {
  img {
    margin-right: 3px;
  }
}

.imageContent {
  color: get($gray, 500);
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.2;
  vertical-align: middle;
}

.postStatus {
  text-align: right;
}

.postTags {
  float: right;
  margin: 25px 0 0;

  @media (max-width: 576px) {
    float: none;
  }
}

.badge {
  background-color: get($green, 600);
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  margin-left: 6px;
  padding: 3px 9px;

  &.tax {
    background-color: $purple;
  }

  &.general {
    background-color: get($summit, 900);
  }
}
