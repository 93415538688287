@use 'scss/base/variables' as *;

.filters {
  position: relative;
  z-index: 2;
}

.pillarFilters {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;

  @media (max-width: 360px) {
    gap: 8px;
  }
}

.pillarButton {
  background: get($summit, 100);
  border-radius: 16px;
  display: flex;
  gap: 12px;
  min-height: 92px;
  padding: 8px 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;

  @media (max-width: 991px) {
    align-items: center;
    justify-content: center;
    min-height: 60px;
  }

  @media (max-width: 575px) {
    gap: 8px;
    min-height: 48px;
    padding: 8px 10px;
  }

  @media (max-width: 360px) {
    gap: 4px;
    padding: 8px;
  }

  svg {
    align-self: center;
    flex-shrink: 0;

    path {
      fill: get($summit, 800);
    }

    path,
    rect {
      transition:
        fill 0.2s ease-in-out,
        stroke 0.2s ease-in-out;
    }
  }

  .label {
    color: get($summit, 800);
    font-size: 1.6rem;
    font-weight: 500;
    transition: color 0.2s ease-in-out;

    @media (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.3;
    max-width: 250px;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &.active,
  &:hover {
    color: #fff;

    .label {
      color: #fff;
    }

    svg {
      path,
      rect {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  &:hover {
    &.all {
      background: get($summit, 600);
    }

    &.keep {
      background: get($keep, 400);
    }

    &.earn {
      background: get($earn, 400);
    }

    &.grow {
      background: get($grow, 400);
    }
  }

  &.active {
    &.all {
      background: get($summit, 800);
    }

    &.keep {
      background: get($keep, 700);
    }

    &.earn {
      background: get($earn, 700);
    }

    &.grow {
      background: get($grow, 700);
    }
  }

  &.all {
    align-items: center;
    flex-grow: 1;

    svg {
      margin-right: 5px;
      width: 24px;

      @media (max-width: 575px) {
        width: 14px;
      }
    }
  }

  &.keep,
  &.earn,
  &.grow {
    width: 28%;

    @media (max-width: 991px) {
      width: 24%;
    }

    svg {
      width: 22px;

      @media (max-width: 575px) {
        width: 14px;
      }
    }
  }

  &.earn {
    svg {
      width: 18px;

      @media (max-width: 575px) {
        width: 12px;
      }
    }
  }
}

.separator {
  background: get($summit, 700);
  margin: 0 12px;
  width: 1px;

  @media (max-width: 767px) {
    display: none;
  }
}

.filterButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;

  &.mobileHidden {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.filterButton {
  @media (max-width: 991px) {
    width: calc(33.33% - 14px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  button {
    width: 100%;
  }
}

.clearButton {
  @media (max-width: 991px) {
    width: 100%;
  }

  svg {
    margin-right: 8px;
  }

  &:not(:hover) {
    svg path {
      stroke: get($midnight, 500);
    }
  }
}

.mobileFilterToggleButton {
  display: none;
  margin-bottom: 20px;
  margin-left: auto;

  @media (max-width: 767px) {
    display: flex;
  }

  .filterIcon {
    margin-right: 8px;
    width: 16px;
  }

  &:hover {
    svg path {
      stroke: #fff;
    }
  }
}
