@use 'scss/base/variables' as *;

.container {
  padding-top: 0;

  @media (max-width: 991px) {
    margin: 0 auto;
    max-width: 100%;
    width: 800px;
  }
}

.activityHeader {
  overflow: hidden;
  transition: 0.2s all;

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.existingPostsWrapper {
  margin-top: 30px;
}

.postCard {
  &:nth-child(1) {
    margin-top: 0;
  }

  a.link {
    word-wrap: break-word;
  }
}
