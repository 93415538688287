@use 'scss/base/variables' as *;

.wrapper {
  align-items: flex-start;
  border: 1px solid get($gray, 200);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  margin: 20px 0;
  padding: 16px;

  @media (max-width: 479px) {
    flex-direction: column;
  }
}

.alpineBalanceIconWrapper {
  background-color: get($summit, 900);
  border-radius: 8px;
  height: 36px;
  padding: 6px;
  width: 36px;

  img {
    height: 24px;
  }
}

.container {
  width: 100%;
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.secondaryText,
.infoText {
  color: get($midnight, 200);
}

.dividerSmall {
  border-top: 1px solid get($gray, 200);
  margin: 10px 0;
  width: 24px;
}

.checkboxWrapper {
  border: 1px solid get($gray, 200);
  border-radius: 10px;
  padding: 10px;
  width: calc(50% - 5px);

  .secondaryText {
    div {
      margin-left: 36px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.checkboxDisabled {
  background-color: get($gray, 100);

  label {
    color: get($gray, 200);
  }

  &.checked {
    label {
      color: get($gray, 900);
    }

    .secondaryText {
      color: get($midnight, 200);
    }
  }
}

.infoText {
  font-size: 1.2rem;
  margin: 10px auto;

  svg {
    margin-bottom: -3px;
    margin-right: 5px;
  }
}
