@use 'scss/base/variables' as *;

.headerBanner {
  align-items: center;
  background: get($gray, 900);
  display: flex;
  justify-content: center;
  min-height: 45px;
  padding: 8px 15px;
  width: 100%;
  z-index: 2;
}

.bannerText {
  color: #fff;
  text-align: center;
}
