@use 'scss/base/variables' as *;

.heading {
  margin-bottom: 5px;
}

.investmentsInfo {
  border-bottom: 1px solid get($gray, 200);
  margin: 0 -40px;
  padding: 25px 40px 10px;

  @media (max-width: 991px) {
    margin: 0 -20px;
    padding: 25px 20px;
  }
}

.textContent {
  background-image: url('images/icons/warning.svg?url');
  background-position: left top 5px;
  background-repeat: no-repeat;
  padding-left: 35px;
}

.investment {
  display: flex;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.downloadLink {
  align-items: center;
  display: flex;
}

.downloadIcon {
  font-size: 2rem;
  margin-right: 10px;
}
