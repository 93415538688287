@use 'scss/base/variables' as *;

.documentList {
  clear: both;
  margin-top: 30px;

  ul {
    padding: 0;
  }
}

.docItem {
  border-bottom: 1px solid #eee;
  list-style: none;
  margin-bottom: 15px;
  padding: 8px 0;
  position: relative;
}

.docIcon {
  margin-right: 15px;
}

.download {
  position: absolute;
  right: 0;
  top: 11px;

  @media (max-width: 400px) {
    display: inline-block;
    margin-right: 20px;
    position: static;
  }

  i {
    font-size: 2.2rem;
  }
}

.downloadAll {
  float: right;
  margin-bottom: 10px;

  @media (max-width: 575px) {
    display: block;
    float: none;
    margin-top: 20px;
    width: 110px;
  }

  i {
    margin-left: 8px;
  }
}

.title {
  cursor: pointer;
  padding-right: 105px;
  position: relative;

  @media (max-width: 400px) {
    display: block;
    margin-bottom: 20px;
    padding-right: 0;
  }

  button {
    align-items: center;
    color: #505050;
    display: flex;
    text-align: left;
  }
}
