@use 'scss/base/variables' as *;

.footer {
  background: get($summit, 900);
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.4;
  padding-top: 100px;
  position: relative;
}

.container {
  @media (min-width: 1200px) {
    max-width: 1170px !important;
  }
}

.footerLogo {
  display: block;
  margin-bottom: 45px;
  width: 250px;

  @media (max-width: 767px) {
    margin: 0 auto 45px;
  }
}

.contact {
  @media (max-width: 767px) {
    margin-bottom: 50px;
    text-align: center;
  }

  div {
    margin-top: 10px;
  }

  a {
    color: #fff;

    @media (max-width: 991px) {
      display: block;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.menuColumns {
  @media (max-width: 991px) {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    color: #fff;
    display: block;
    font-weight: 300;
    margin-bottom: 16px;
    padding: 2px 0;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.6;
      text-decoration: none;
    }
  }
}

.menuColumnsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }

  > div {
    flex: initial;
    max-width: none;
  }
}

.menuColumn {
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    color: #fff;
    display: block;
    font-weight: 300;
    margin-bottom: 16px;
    padding: 2px 0;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.6;
      text-decoration: none;
    }
  }
}

.footerBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin: 100px 0;

  @media (max-width: 767px) {
    margin: 10px 0 50px;
  }
}

.footerLeft,
.footerRight {
  @media (max-width: 767px) {
    float: none;
    text-align: center;
    width: auto;
  }
}

.footerLeft {
  float: left;
  width: 50%;

  p {
    margin-bottom: 30px;
  }
}

.footerRight {
  float: right;
  text-align: right;
  width: 50%;
}

a.footerSocial {
  color: #fff;
  margin-left: 35px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.6;
    text-decoration: none;
  }

  &:first-child {
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  i {
    font-size: 2.8rem;
    transition: 0.2s ease-in-out;
  }
}

.disclaimer {
  padding-bottom: 40px;

  @media (max-width: 767px) {
    padding-top: 40px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 10px;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.6;
      }
    }
  }
}
