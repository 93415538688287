/* stylelint-disable declaration-no-important */

.grecaptcha-badge {
  bottom: 8px !important;
  left: 0 !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  width: 70px !important;

  &:hover {
    width: 256px !important;
  }
}
