.documentPreview {
  margin-bottom: 30px;

  img {
    max-width: 100%;

    &.fileImage {
      max-height: 300px;
    }

    &.filePdf {
      width: 70px;
    }
  }
}
