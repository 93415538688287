.contentRow {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.sponsorRow {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-bottom: 20px;
  min-width: 30%;
}

.tooltipContent {
  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;

    li + li {
      padding-top: 20px;
    }
  }
}

.infobar {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.investButton {
  flex-shrink: 0;
  min-width: 200px;
}

.sponsor {
  align-items: center;
  display: flex;
}

.companyLogo {
  display: inline-block;
  height: 52px;
  margin-right: 20px;
  width: 52px;

  @media (max-width: 479px) {
    height: 45px;
    margin-right: 0;
    width: 45px;
  }

  img {
    width: 100%;
  }
}

.companyName {
  display: inline-block;
  font-size: 2rem;
  font-weight: 500;

  @media (max-width: 767px) {
    display: none;
  }
}

.addressWrap {
  @media (max-width: 991px) {
    display: none;
  }
}

.address {
  align-items: center;
  display: flex;
  font-size: 1.6rem;

  i {
    font-size: 2rem;
    margin-right: 8px;
  }
}
