@use 'scss/base/variables' as *;
@use 'sass:color';

p {
  margin-bottom: 15px;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: get($midnight, 800);
  }
}

b,
strong {
  font-weight: 600;
}

sup {
  vertical-align: super;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.label {
  font-weight: 400;
}

.text-label {
  color: get($gray, 500);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.force-inline {
  display: inline !important;
}

.text-green {
  color: get($green, 600);
}

.text-orange {
  color: get($poppy, 500);
}

.text-yellow {
  color: get($yellow, 600);
}

.text-blue {
  color: get($summit, 900);
}

.text-red {
  color: get($red, 600);
}

.text-dark {
  color: get($gray, 900);
}

.text-medium-gray {
  color: get($gray, 500);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.weight-md {
  font-weight: 500;
}

.weight-md-heavy {
  font-weight: 600;
}

.strong {
  font-weight: 700;
}

.size-12 {
  font-size: 1.2rem;
}

.size-16 {
  font-size: 1.6rem;
}

.text-blue-light {
  color: get($summit, 700);
}

.section-subheading {
  color: get($gray, 500);
  font-size: 1.6rem;
  margin: 0 0 25px;
}

.field-disclaimer {
  color: get($gray, 500);
  margin-top: -15px;
}

.error-message {
  color: get($red, 600);
}

a.text-link,
button.text-link,
.text-link-light {
  appearance: none;
  border: 0;
  box-shadow: none;
  color: $link-color;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;

  &:not(:disabled) {
    &:hover,
    &:focus {
      color: $link-color-hover;
      text-decoration: none;
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  &.loading {
    cursor: wait;
  }
}

.text-link-light {
  color: #fff;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      opacity: 0.4;
    }
  }

  &:disabled {
    opacity: 0.6;
  }
}

button.text-link.danger {
  color: get($red, 600);

  &:hover,
  &:focus {
    color: color.adjust(get($red, 600), $lightness: 10%);
    text-decoration: underline;
  }
}

a.text-link:enabled {
  &:hover,
  &:focus,
  &:active {
    color: #0082ac;
  }
}

.underline {
  text-decoration: underline;

  :enabled {
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline !important;
    }
  }
}

.caret {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px dashed;
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
}

.numbered-todo-list {
  text-align: left;

  .item {
    color: get($gray, 900);
    display: flex;
    font-size: 1.6rem;
    margin-bottom: 27px;

    &.present,
    &.future {
      .item-icon {
        border: 1px solid;
        border-radius: 50%;
      }

      .item-number {
        margin-top: 1px;
      }
    }

    &.present {
      .item-icon {
        box-shadow: 0 0 1px get($gray, 900); // Hack to simulate 1.5px border width
      }
    }

    &.future {
      color: get($gray, 300);

      .item-icon {
        border-style: dashed;
      }
    }

    &.complete {
      color: get($summit, 500);

      .item-icon::before {
        opacity: 1;
      }

      .item-number {
        display: none;
      }
    }

    .item-icon {
      align-items: center;
      display: inline-flex;
      flex: 0 0 20px;
      height: 20px;
      justify-content: center;
      margin-right: 9px;
      position: relative;
      vertical-align: middle;
      width: 20px;

      &::before,
      &::after {
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &::before {
        background-image: url('images/icons/check-circle-light.svg');
      }

      &::after {
        background-image: url('images/icons/check-circle-light.svg');
      }
    }

    .item-text {
      line-height: 1.3;
    }
  }
}
