@use 'scss/base/variables' as *;

.investmentPosts {
  margin-top: 40px;
}

.sidebar {
  margin-top: 0;
  padding: 20px;
  position: sticky;
  top: 20px;
  z-index: 1;

  @media (max-width: 991px) {
    display: block;
  }

  h6 {
    margin: 0 0 20px;
  }
}

.monthSection {
  margin-bottom: 20px;

  &:first-child .monthYear {
    padding-top: 0;
  }

  .monthYear {
    line-height: 1.3;
    padding: 40px 30px 0 0;
    width: 150px;

    .month {
      font-size: 2rem;
      font-weight: 700;
    }
  }
}

.posts {
  width: 100%;
}

.postWrap {
  display: flex;
  padding-top: 20px;

  &:last-child {
    .timeline {
      .line {
        height: 100%;
      }
    }
  }
}

.postRight {
  flex-grow: 1;
  margin-left: 20px;

  @media (max-width: 576px) {
    margin-left: 10px;
  }
}

.postMeta {
  line-height: 1.3;
  position: relative;
}

.description {
  font-weight: 500;
  margin-bottom: 0;
}

.date {
  display: none;
}

.timeline {
  position: relative;
}

.dot {
  background: get($gray, 500);
  border: 4px solid #f7f7f7;
  border-radius: 50%;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 1;
}

.line {
  background: #eee;
  height: calc(100% + 20px);
  left: 9px;
  position: absolute;
  top: 0;
  width: 2px;
}
