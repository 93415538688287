.modal {
  :global(.MuiBox-root) {
    overflow: visible;

    @media (min-width: 768px) {
      width: 500px;
    }
  }

  :global(.MuiCard-root) {
    overflow: visible;
  }
}
