@use 'scss/base/variables' as *;

.capitalCall {
  h2 {
    @media (max-width: 400px) {
      font-size: 2.4rem;
      margin: 10px 0 30px;
    }
  }

  h4 {
    margin: 0 0 30px;

    span {
      color: get($gray, 600);
      display: block;
      margin-top: 20px;
    }
  }
}

button.back {
  cursor: pointer;
  float: right;
  height: 50px;
  padding: 14px 30px;

  @media (max-width: 767px) {
    float: left;
    padding-left: 0;
  }
}

.contactBox {
  padding: 40px 15px 30px;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0 -15px;
  }

  h3 {
    margin: 0 0 30px;

    @media (max-width: 479px) {
      font-size: 1.6rem;
    }
  }
}
