@use 'scss/base/variables' as *;

.pendingInviteImg {
  background-color: rgb(188, 194, 197);
  border-radius: 50%;
  font-size: 1.4rem;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 10px;
  position: static;
  text-align: center;
  width: 40px;

  span {
    color: #fff !important;
    text-transform: uppercase;
  }
}

.inviteMetaDetails {
  color: get($gray, 300);
  font-size: 1.6rem;

  .smallText {
    font-size: 1.2rem;
  }

  img {
    border-radius: 50%;
    height: 40px;
    left: 0;
    min-width: 0;
    position: static;
    width: 40px;
  }
}

.pendingInvites {
  a,
  label {
    font-size: 1.4rem;
  }
}

.metaBlock {
  float: left;
  margin: 0 0 4px;
}

.metaTitle {
  color: get($gray, 300);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 22px;
  text-transform: uppercase;
}

.resendButton {
  margin: 5px 0 15px;
}

.labelYellow {
  background-color: get($yellow, 600);
  color: #fff;
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 5px;

  @media (min-width: 768px) {
    padding: 10px 15px 8px 17px;
  }
}
