@use 'scss/base/variables' as *;

.timer {
  align-items: center;
  background: get($green, 600);
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  height: 24px;
  padding: 0 12px;

  &.red {
    background: get($red, 600);
  }
}
