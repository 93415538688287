.formButtons {
  @media (max-width: 479px) {
    align-items: end;
    width: 100%;

    &.multiButton {
      flex-direction: column-reverse;
    }

    button {
      width: 100%;
    }

    a {
      min-width: 44px;
    }
  }
}

.secondaryButtons {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;

  @media (max-width: 479px) {
    width: 100%;
  }
}

.submitButton {
  min-width: 150px;

  @media (max-width: 479px) {
    width: 100%;
  }
}
