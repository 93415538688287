@use 'scss/base/variables' as *;

.wrap {
  align-items: flex-start;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 36px 60px 120px 36px;
  position: relative;

  @media (max-width: 1439px) {
    padding: 36px 30px 90px 36px !important;
  }

  @media (max-width: 767px) {
    display: none;
  }

  &.grayBackground {
    background: get($summit, 200);
  }

  &.blueBackground {
    background: get($midnight, 500);

    h1,
    p {
      color: #fff;
    }

    a svg path {
      fill: #fff;
    }
  }

  &.barkBackground {
    background: get($bark, 300);
  }

  h1 {
    font-size: 6.4rem;
    line-height: 1;
  }

  .content {
    margin-top: auto;
  }
}
