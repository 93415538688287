.unlockInstructions {
  text-align: center;

  p {
    margin: 40px 0;

    br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  button {
    margin: 0 auto;
    max-width: 100%;
    width: 200px;
  }
}
