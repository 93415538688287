@use 'scss/base/variables' as *;

.legendDataPoint {
  margin-bottom: 5px;
  min-height: 36px;
}

.dataPoint {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.description {
  align-items: center;
  display: flex;
  padding-right: 15px;
}

.swatch {
  display: inline-block;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.name {
  display: inline-block;
  line-height: 1.3;
}

.value {
  margin-left: auto;
}

.percentage {
  font-weight: 700;
  margin-right: 10px;
}

.toggleRowButton {
  border: 2px solid transparent;
  border-radius: 0;
  padding: 6px;

  &:hover {
    background: get($gray, 100);
    border-color: get($gray, 100);

    .iconCircle {
      background: $link-color;
    }
  }

  &:focus {
    border-color: $purple;
    box-shadow: 0 0 3px $purple;

    .iconCircle {
      background: $link-color;
    }
  }
}

.iconCircle {
  align-items: center;
  background: get($gray, 600);
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: 20px;

  i {
    color: #fff;
    font-size: 0.8rem;
    transition: transform 0.2s ease-in-out;

    &.down {
      transform: rotate(90deg);
    }
  }
}

.collapse {
  padding: 0 10px 5px 0;
}

.collapseItem {
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  margin-top: 5px;
}

.collapseName {
  padding-right: 15px;
}
