.documentInstructions {
  align-items: center;
  display: flex;

  img {
    margin-right: 18px;
    width: 20px;
  }
}

.documentError {
  // Created with a CSS filter generator to replicate #eb5424
  filter: invert(23%) sepia(94%) saturate(6573%) hue-rotate(17deg)
    brightness(96%) contrast(91%);
}
