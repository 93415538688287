// Used to apply a consistent width to inputs that aren't inside react-grid-system columns
.input-fixed-width {
  max-width: 100%;
  width: 320px;
}

.input-fixed-width-wide {
  max-width: 100%;
  width: 500px;
}
