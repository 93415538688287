@forward '../../../node_modules/@equitymultiple/react-eui/dist/variables';

@use '../../../node_modules/@equitymultiple/react-eui/dist/variables' as *;
@use 'sass:map' as *;

@forward 'sass:map';

// Deprecated, remove when all components using this have been redesigned or replaced
$purple: #694bcc;

$link-color: get($midnight, 500);
$link-color-hover: get($midnight, 800);

$card-box-shadow: 0 0 2px 0 rgba(42, 43, 48, 0.06);
$card-border-radius: 16px;
