.phoneFieldWrap {
  display: flex;
  gap: 20px;

  @media (max-width: 575px) {
    display: block;
  }

  > div {
    &:first-child {
      width: 45%;

      @media (max-width: 575px) {
        width: 100%;
      }
    }

    &:last-child {
      width: 55%;

      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
