@use 'scss/base/variables' as *;

.filterButton {
  width: 160px;
}

.filterActive {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    display: block;
  }

  .filteredBy {
    display: inline-block;
    margin-right: 10px;

    @media (max-width: 576px) {
      display: block;
      margin-bottom: 5px;
    }
  }

  .filterLabel {
    border: 1px solid;
    color: get($gray, 500);
    display: inline-block;
    line-height: 1.3;
    margin-right: 10px;
    padding: 3px 10px;

    @media (max-width: 991px) {
      margin-bottom: 10px;
    }

    &:last-child {
      @media (max-width: 576px) {
        margin-right: 0;
      }
    }
  }

  .actions {
    display: flex;

    .separator {
      margin: 15px;
    }
  }
}
