@use 'scss/base/variables' as *;

// These styles are used on OM for images and image carousels
// TODO: These should be migrated to the offering component stylesheet

.carousel-caption {
  bottom: 5%;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

.carousel-indicators li {
  background-color: get($gray, 300);
  border: 0;

  &.active {
    background-color: get($summit, 900);
  }
}

.carousel-control {
  font-size: 3.6rem;
  padding-top: 20%;
}

.carousel-inner {
  height: 600px;

  @media (max-width: 767px) {
    height: 300px;
  }

  > .item {
    height: 100%;

    > img,
    > a > img {
      margin: auto;
    }
  }
}

.single-image img {
  max-width: 758px;
}
