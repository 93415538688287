.loadingButtonsWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.loadingBackButton {
  width: 50px;
}

.loadingNextButton {
  height: 50px;
  width: 170px;
}
