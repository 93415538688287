.disclaimer {
  display: flex;

  svg {
    flex: 0 0 16px;
    height: 16px;
  }

  p {
    padding-left: 10px;
  }
}
