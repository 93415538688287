.carousel {
  :global(.slider) {
    height: 600px;

    @media (max-width: 991px) {
      height: 500px;
    }

    @media (max-width: 767px) {
      height: auto;
    }
  }

  :global(.carousel-slider .control-arrow) {
    padding: 15px;

    &::before {
      border-width: 16px;
    }
  }

  :global(.carousel-inner) {
    @media (max-width: 767px) {
      height: auto;
    }
  }
}

.slideInner {
  height: 100%;

  img {
    height: 100%;
    object-fit: contain;

    @media (max-width: 767px) {
      height: auto;
    }
  }
}

.caption {
  bottom: 12%;
  color: #fff;
  font-size: 1.6rem;
  position: relative;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 767px) {
    display: none;
  }
}
