@use 'scss/base/variables' as *;

.container {
  padding-top: 78px;

  @media (max-width: 479px) {
    padding: 0 !important;
  }
}

.card {
  margin: 0 0 15px !important;
  padding: 35px 120px 40px;

  @media (max-width: 991px) {
    padding: 35px 35px 40px;
  }

  @media (max-width: 767px) {
    padding: 35px 15px 40px;
  }
}

.header {
  color: get($summit, 900);
  font-size: 5.4rem;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 2.8rem;
  }
}

.subHeader {
  color: get($gray, 500);
  font-size: 2.4rem;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 1.6rem;
    margin: 0 auto 30px;
    max-width: 100%;
    width: 350px;
  }
}

.intro {
  @media (max-width: 767px) {
    text-align: center;
  }

  button {
    @media (max-width: 479px) {
      width: 100% !important;
    }
  }
}
