.overview {
  padding-bottom: 64px !important;

  @media (max-width: 767px) {
    padding-bottom: 251px !important;
  }
}

.tabsList {
  bottom: 38px;
  margin-bottom: -38px;
  position: relative;

  @media (max-width: 767px) {
    bottom: 230px;
    margin-bottom: -190px;
  }
}
