@use 'scss/base/variables' as *;

.subscriptionAgreementWrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  img {
    margin: 0 15px 5px 0;
  }
}

.pendingDoc {
  margin-right: 5px;

  @media (max-width: 576px) {
    display: block;
  }
}

.cardWrap {
  float: right;
  margin-top: -40px;

  @media (max-width: 1400px) {
    float: none;
    margin-top: 0;
  }
}

.docIcon {
  display: inline-block;
  margin-right: 7px;
}

.pending {
  font-style: italic;
}

.transactionRowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;

  @media (max-width: 767px) {
    display: none;
  }
}

.transactionRow {
  /* stylelint-disable-next-line declaration-no-important */
  align-items: center !important;
  background-color: get($gray, 100);
  border-radius: 12px;
  /* stylelint-disable-next-line declaration-no-important */
  margin: 10px 0 !important;
  min-height: 52px;
  padding: 8px 0;

  @media (min-width: 767px) {
    .rowlabel {
      display: none;
    }
  }

  @media (max-width: 767px) {
    gap: 10px;

    .statusLabel {
      text-align: left;
    }
  }
}

.secondaryText {
  color: get($midnight, 200);
  font-size: 1.2rem;
}
