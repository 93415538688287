.investmentTitle {
  align-items: center;
  display: flex;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: block;
  }

  h4 {
    margin: 0;
  }

  h2 {
    margin: 0 30px 0 0;

    @media (max-width: 767px) {
      margin: 0 0 20px;
    }

    &.loading {
      margin-right: 0;
      width: 100%;
    }
  }
}
