@use 'scss/base/variables' as *;

.offeringCard {
  background: #fff;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  height: auto;
  margin: 32px auto 0;
  position: relative;

  &::before {
    border: 2px solid transparent;
    border-radius: $card-border-radius;
    content: '';
    display: block;
    inset: -2px;
    position: absolute;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    width: 345px;
  }

  @media (max-width: 575px) {
    width: 290px;
  }
}

.cardHeader {
  background-position: center;
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 200px;
  padding: 25px 25px 15px;
  position: relative;
  z-index: 1;

  @media (max-width: 1199px) {
    height: 163px;
    padding: 15px;
  }

  @media (max-width: 767px) {
    height: 157px;
  }
}

.cardContent {
  min-height: 132px;
  padding: 25px 25px 15px;
  position: relative;
  z-index: 1;

  @media (max-width: 1199px) {
    padding-bottom: 15px;
  }
}

.propertyInfo {
  color: get($gray, 500);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
}

.offeringTitle {
  margin: 5px 0 10px;
  min-height: 46px;

  @media (max-width: 1199px) {
    font-size: 1.8rem;
  }
}

.button {
  background: rgba(6, 33, 100, 0.05);
  border: 2px solid transparent;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: $link-color;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: auto;
  padding: 19px 15px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  @media (max-width: 1199px) {
    font-size: 1.4rem;
    padding: 15px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    border-color: $purple;
  }
}
