@use 'scss/base/variables' as *;

.hero {
  align-items: center;
  background-color: #ccc;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 465px;
  justify-content: center;
  padding: 30px;

  @media (max-width: 767px) {
    border: 1px solid #dcdcdc;
    height: auto;
    padding: 50px 30px;
  }

  .textWrap {
    margin: 0 auto;

    h1 {
      color: #fff;
      margin: 0 auto;
      text-align: center;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      @media (max-width: 400px) {
        font-size: 3.6rem;
      }
    }

    .date {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 500;
      margin: 15px 0;
      text-align: center;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
}

.info {
  padding: 30px 60px 0;

  @media (max-width: 575px) {
    padding: 30px 0 0;
  }
}

.radioDescription {
  padding-top: 20px;

  h4 {
    margin: 0 0 10px;
  }

  ul {
    padding: 0 0 0 15px;
  }
}

.interestDisclaimerWrap {
  text-align: left;
}
