@use 'scss/base/variables' as *;

.earningsTable {
  height: calc(100% - 62px);

  @media (max-width: 991px) {
    height: auto;
    margin-bottom: 0 !important;
  }
}

.cardHeading {
  margin: 0 0 30px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.flexTop {
  width: 100%;
}

.earningsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  &.totalRow {
    border-top: 1px solid get($gray, 200);
    font-weight: 700;
    margin: auto 0 0;
    padding-top: 15px;
  }

  span {
    flex-basis: 80px;
  }
}
