@use 'scss/base/variables' as *;

.keyMetrics {
  margin-bottom: 20px;
  padding-top: 10px;

  :global(.svg-inline--fa) {
    color: get($summit, 900);
    height: 36px;
    margin-bottom: 20px;
    width: 36px;
  }
}

.infoIcon {
  margin: 0 9px !important;
  position: static !important;
}

.textLabel {
  min-height: 20px;
}

.metaData {
  margin-bottom: 30px;
}
