.galleryContent {
  margin: 0 -15px;
  padding-top: 63px;
}

.back {
  color: #fff;
  display: block;
  font-size: 1.6rem;
  margin: 0 auto 10px;
  opacity: 1;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &.invisible {
    opacity: 0;
    visibility: hidden;
  }

  i {
    margin-right: 10px;
  }
}

.image {
  cursor: pointer;
  float: left;
  margin-bottom: 30px;
  padding: 0 15px;
  text-align: center;
  width: 33.3333%;

  @media (max-width: 991px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  &.fullSize {
    cursor: default;
    height: 100%;
    position: relative;
    width: 100%;
  }

  img {
    max-width: 100%;
  }
}
