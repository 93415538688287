@use 'scss/base/variables' as *;

.humane,
.humane-flatty {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  color: get($gray, 900);
  filter: progid:dximagetransform.microsoft.alpha(opacity=100);
  font-size: 1.6rem;
  font-weight: 500;
  left: 50%;
  min-height: 80px;
  opacity: 0;
  padding: 20px;
  padding-left: 90px;
  position: fixed;
  top: 15px;
  transform: translate(-50%, -100px);
  transition: all 0.4s ease-in-out;
  width: 500px;
  max-width: calc(100% - 30px);
  z-index: 2001;
  align-items: center;
}

.humane p,
.humane-flatty p,
.humane ul,
.humane-flatty ul {
  margin: 0;
  padding: 0;
}

.humane ul,
.humane-flatty ul {
  list-style: none;
}

.humane.humane-flatty-success,
.humane-flatty.humane-flatty-success,
.humane.humane-flatty-error,
.humane-flatty.humane-flatty-error {
  &::before {
    font-family: fontAwesome;
    font-size: 3rem;
    height: 100%;
    left: 0;
    min-height: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 65px;
    color: #fff;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.humane.humane-flatty-success,
.humane-flatty.humane-flatty-success {
  &::before {
    background: get($green, 600);
    content: '\f00c';
  }
}

.humane.humane-flatty-error,
.humane-flatty.humane-flatty-error {
  &::before {
    background: #f5a623;
    content: '\f071';
  }
}

.humane-animate,
.humane-flatty.humane-flatty-animate,
.humane-js-animate,
.humane-flatty.humane-flatty-js-animate {
  opacity: 1;
  transform: translate(-50%, 0);
  display: flex !important;
}
