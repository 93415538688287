@use 'scss/base/variables' as *;

.offeringSignUp {
  margin-top: 0;

  @media (max-width: 991px) {
    margin: 30px 0;
  }

  h3 {
    margin-top: 0;

    a {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 0;
    max-width: 520px;
  }
}

.flexWrap {
  align-items: center;
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.imageWrap {
  background: get($gray, 200);
  flex-shrink: 0;
  height: 150px;
  margin-right: 30px;
  width: 278px;

  @media (max-width: 767px) {
    margin: 0 auto 30px;
  }

  @media (max-width: 400px) {
    max-width: 100%;
    width: auto;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.textWrap {
  width: 100%;
}
