.recoveryCodes {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  font-family: monospace;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 100%;
  padding: 10px;
  width: 300px;
}

.downloadButton {
  display: block;
  margin-bottom: 30px;
  width: fit-content;

  i {
    margin-right: 10px;
  }
}
