.row {
  align-items: stretch;
  min-height: calc(100vh - 40px);

  @media (max-width: 767px) {
    min-height: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 120px 0 55px !important;

  @media (max-width: 1439px) {
    padding-right: 50px !important;
  }

  @media (max-width: 991px) {
    padding-top: 40px !important;
  }

  @media (max-width: 767px) {
    padding: 0 15px !important;
  }
}

.mainContent {
  padding: 30px 0;
}

.topLinks,
.bottomLinks {
  display: flex;
  font-size: 1.6rem;
}

.topLinks {
  justify-content: space-between;

  div a {
    margin-left: 12px;
  }

  div:only-child {
    margin-left: auto;
  }
}

.bottomLinks {
  gap: 30px;
  justify-content: flex-end;
}

.backLink {
  background-image: url('images/icons/arrow-back.svg?url');
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 30px;
}
