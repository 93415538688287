@use 'scss/base/variables' as *;

.passwordField {
  [class*='helperText'] {
    margin: 20px 0 0;
    width: 100%;
  }
}

.strengthText {
  color: get($red, 600);

  &.medium {
    color: get($poppy, 500);
  }

  &.strong {
    color: get($green, 600);
    font-weight: 500;
  }
}

.strengthMeter {
  column-gap: 8px;
  display: flex;
  height: 8px;
  margin: 8px 0 20px;

  div {
    background: get($red, 600);
    border-radius: 2px;
    width: 33%;
  }

  &.medium div {
    background: get($poppy, 500);
  }

  &.strong div {
    background: get($green, 600);
  }
}

.requirementsList {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1439px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.requirement {
  font-size: 1.2rem;
  margin-top: 3px;
  padding-left: 17px;
  position: relative;
  transition: color 0.15s ease-in-out;

  @media (max-width: 1439px) {
    margin: 8px 15px 0 0;
  }

  &::before {
    background: get($summit, 200);
    border-radius: 2px;
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 3px;
    transition:
      border-color 0.15s ease-in-out,
      background 0.15s ease-in-out;
    width: 10px;
  }

  &.met {
    color: get($gray, 900);

    &::before {
      background: get($green, 600);
    }
  }
}
