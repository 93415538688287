@use 'scss/base/variables' as *;

@import 'https://fonts.googleapis.com/css2?family=Jomolhari&family=Kalam&family=The+Nautigal&display=swap';

.kalam {
  font-family: Kalam, sans-serif;
}

.theNautigal {
  font-family: 'The Nautigal', cursive;

  label {
    font-size: 2.4rem !important;
  }
}

.jomolhari {
  font-family: Jomolhari, serif;
}

.radioButtons {
  display: flex;
  width: 100%;
}

.radioWrap {
  margin-right: 10px;
  position: relative;
  width: calc(33.3333% - 10px);

  @media (max-width: 400px) {
    font-size: 1.4rem;
    width: calc(33.3333% - 7px);
  }

  &:last-child {
    margin-right: 0;
  }

  label {
    align-items: center;
    border: 2px solid get($gray, 200);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-size: 1.6rem;
    height: 80px;
    justify-content: center;
    margin: 0;
    padding: 12px;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border-color: get($gray, 300);
    }

    &::before,
    &::after {
      display: none;
    }
  }

  input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -1;

    &:checked + label {
      border-color: get($summit, 900);

      &.disabled {
        color: #ccc;
      }
    }
  }
}

.renderedSignature {
  align-items: center;
  border: 2px dashed get($gray, 200);
  border-radius: 8px;
  display: flex;
  font-size: 3.2rem;
  justify-content: center;
  line-height: 1.1;
  margin: 15px 0 30px;
  min-height: 80px;
  padding: 6px 15px;
  text-align: center;
  width: 100%;
  word-break: break-all;

  &.theNautigal {
    font-size: 4.4rem;
  }
}
