.googleButton {
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  svg {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
  }
}
