@use 'scss/base/variables' as *;

.progressBarBorder {
  background: linear-gradient(
    to right,
    get($summit, 600) 0%,
    get($summit, 900) 100%
  );
  height: 6px;
  left: 0;
  position: absolute;
  top: -6px;

  &.animate {
    transition: width 0.6s ease-out;
  }

  &.warning {
    background: get($poppy, 500);
    transition: none;
  }

  &.solidBlue {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    background: get($summit, 900);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.6s ease-in-out;
    width: 100%;
  }
}
