.mediaDescription {
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 10px;
  text-align: center;
}

.metaData,
.metaBlock,
.metaBlockData {
  font-size: 1.8rem;
  line-height: 1.5;
}
