@use 'scss/base/variables' as *;

.documentDownload {
  align-items: center;
  border: 2px solid get($gray, 300);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  min-height: 48px;
  padding: 6px 12px;
  width: 100%;

  @media (max-width: 575px) {
    display: block;
  }

  &:disabled {
    border-color: get($gray, 200);
  }
}

.documentName {
  align-items: center;
  display: flex;
  padding-right: 15px;
  text-align: left;
}

.documentIcon {
  height: 32px;
  margin-right: 20px;

  svg {
    height: 100%;
  }
}

.downloadText,
.downloadedText {
  align-items: center;
  display: flex;
  font-size: 1.6rem;

  @media (max-width: 575px) {
    margin-top: 12px;
  }
}

.downloadText {
  color: $link-color;

  i {
    margin-right: 15px;
  }
}

.downloadedText {
  color: get($gray, 500);

  img {
    margin-right: 15px;
    width: 16px;
  }
}

.loader {
  @media (max-width: 575px) {
    margin-top: 12px;
  }

  > div {
    height: 20px;
    width: 20px;
  }
}

.error {
  border-color: get($red, 600);
  box-shadow: 0 0 3px get($red, 600);
}
