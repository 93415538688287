@use 'scss/base/variables' as *;

.capitalCallTimer {
  margin-bottom: 30px;

  &:not(.small) {
    .tile {
      float: left;
      width: 33.3333%;
    }
  }

  &.small {
    .tile {
      width: 80px;
    }
  }

  &.orange .timer .tile {
    background: get($poppy, 500);
  }
}

.navTitle {
  font-weight: 700;
  margin: 15px 0;
}

.timer {
  border-radius: 3px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.tile {
  background: get($summit, 900);
  border: 1px solid #fff;
  color: #fff;
  padding: 11px 8px 9px;
  text-align: center;
}

.tileLabel {
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
}

.tileValue {
  font-size: 2rem;
}
