@use 'scss/base/variables' as *;

.previousTransactions {
  color: get($gray, 500);
  font-size: 1.2rem;

  strong {
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding-left: 17px;
  }
}
