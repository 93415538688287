@use 'scss/base/variables' as *;

.hex {
  margin-top: 40px;
  max-width: 100%;
}

.button {
  max-width: 100%;
  width: 320px;
}

.completeButton {
  margin-bottom: 50px;
}
