@use 'scss/base/variables' as *;

.card {
  background: #fff;
  border: 1px solid get($gray, 200);
  border-radius: 20px;
  display: block;
  margin-bottom: 16px;
  padding: 10px 16px;
  word-wrap: break-word;

  @media (max-width: 991px) {
    flex-grow: 0;
    margin-bottom: 0;
    width: calc(50% - 7.5px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  h6 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    transition: color 0.2s ease-in-out;
  }
}

.label {
  color: get($summit, 700);
  font-size: 1.2rem;
  font-weight: 600;
}

.large {
  padding: 20px 24px;

  .label {
    font-size: 1.4rem;
  }
}
