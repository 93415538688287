@use 'scss/base/variables' as *;

.toggle {
  background-color: rgba(get($gray, 300), 0.2);
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  margin: 5px 0;
  position: relative;
  user-select: none;
  width: 60px;

  &.toggleSelected {
    background-color: get($green, 600);
    border: 2px solid get($green, 600);

    .toggleSwitch {
      border: 0;
      box-shadow: 0 0 3px rgba(#494b59, 0.2);
      left: 24px;
      margin: 0;
      top: 0;
    }
  }
}

.toggleSwitch {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(#494b59, 0.2);
  display: block;
  height: 32px;
  left: 5px;
  margin: -3px 0 0 -3px;
  position: absolute;
  top: 5px;
  transition: left 0.3s;
  width: 32px;
}

.cashflowMeta {
  margin-bottom: 50px;

  @media (max-width: 700px) {
    display: none;
  }
}

.chartTooltip {
  &::after {
    border: solid transparent;
    border-color: transparent;
    border-top-color: rgba(42, 43, 48, 0.96);
    border-width: 5px;
    content: '';
    height: 0;
    left: 90%;
    margin-left: -50%;
    pointer-events: none;
    position: absolute;
    top: 145%;
    width: 0;
  }
}
