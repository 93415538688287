.investmentAccount {
  h5 {
    margin-bottom: 20px;
  }

  hr {
    margin: 30px 0;
  }
}

.bankAccount {
  margin-bottom: 20px;
}

.newBank {
  float: right;

  @media (max-width: 767px) {
    display: block;
    float: none;
    margin-top: 20px;
  }
}

.editLinks {
  margin-top: 30px;

  p {
    margin-bottom: 20px;
  }

  a,
  button {
    @media (max-width: 991px) {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}

.beneficialOwnerEdit {
  position: relative;
  top: 21px;

  @media (max-width: 767px) {
    position: static;
  }
}

.beneficialOwnerDelete {
  margin-left: 20px;
  position: relative;
  top: 21px;

  @media (max-width: 767px) {
    position: static;
  }
}
