@use 'scss/base/variables' as *;

.updatedText img {
  margin-right: 8px;
}

.requestChangeMobile {
  display: none;
  margin-bottom: 50px;
  width: 100%;

  @media (max-width: 767px) {
    background: get($summit, 900);
    display: block;
    margin-bottom: 20px;
  }
}

.updatedDetails {
  align-items: center;
  color: get($gray, 500);
  display: flex;
  height: 80px;

  @media (max-width: 767px) {
    height: auto;
    margin-bottom: 20px;
  }

  br {
    @media (max-width: 1199px) {
      display: none;
    }
  }
}
