.illustration {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 249px;

  @media (max-width: 1199px) {
    width: 200px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.headingWithIllustration {
  @media (max-width: 991px) {
    padding-right: 200px;
  }
}
