@use 'scss/base/variables' as *;

.currentYear td:first-child {
  background-color: get($summit, 900);
  border-top: 1px solid get($summit, 900);
  padding: 0;
  width: 20px;
}

.fileIcon {
  margin-bottom: 14px;

  @media (max-width: 600px) {
    width: 20px;
  }
}

.titleLink {
  color: get($gray, 900);
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;

  @media (max-width: 479px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.documentsTab {
  box-shadow: 0 1px 2px rgba(42, 43, 48, 0.15);
  display: flex;
  margin: 40px 0 30px;

  @media (max-width: 767px) {
    display: block;
  }

  button {
    width: 100%;

    &.white {
      background: #fff;
    }

    &:focus {
      background: $link-color-hover;
      color: #fff;
    }

    &:active {
      box-shadow: none;
      color: #fff !important;
    }
  }
}

.docsCard {
  margin-top: 30px;
  padding: 0;

  table {
    border-collapse: separate;
    width: 100%;

    th:first-child {
      padding: 0;
    }

    tbody {
      tr {
        td {
          &:nth-child(2) {
            text-align: right;
            width: 100px;

            @media (max-width: 767px) {
              width: auto;
            }

            @media (max-width: 479px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.noDocuments {
  padding: 40px 15px;
  text-align: center;
}

.table {
  border-collapse: separate;
  width: 100%;

  tbody {
    tr {
      overflow: auto;

      td {
        padding: 40px 10px;
        vertical-align: middle;
      }
    }
  }
}
