@use 'scss/base/variables' as *;

/* stylelint-disable declaration-property-unit-allowed-list */
/* stylelint-disable unit-disallowed-list */

.requestInvite {
  display: block;
  height: 100vh;
  justify-content: space-between;
  margin: -60px 0 !important;
  min-height: 1300px;
  width: 100%;

  @media (min-width: 992px) {
    display: flex;
    min-height: 800px;
  }
}

.inviteContent {
  background: #2d2e33;
  left: 0;
  margin: 110px auto 0;
  position: relative;
  width: 90%;

  @media (min-width: 992px) {
    left: 12%;
    margin: 0;
    width: 70%;
  }
}

.imageOverlay {
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media (min-width: 992px) {
    padding: 120px 0 0;
  }

  h1 {
    color: #fff;
    left: 6.5%;
    margin: 28px 0 15px;
    position: relative;
    top: 26%;
    width: 80%;
    word-spacing: 4px;

    @media (min-width: 992px) {
      left: 12%;
    }
  }

  p {
    color: rgba(#fff, 0.8);
    font-size: 1.6rem;
    font-weight: 700;
    left: 6.5%;
    line-height: 1.8em;
    margin-top: -80px;
    position: relative;
    text-align: left;
    top: 80px;
    width: 80%;

    @media (min-width: 992px) {
      left: 12%;
      margin-top: 0;
      top: 12%;
      width: 51%;
    }
  }

  .hiwButton {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    left: 6.5%;
    margin-bottom: 20px;
    opacity: 1;
    position: relative;
    top: 80px;
    width: fit-content;

    &:hover {
      color: $link-color;
    }

    @media (min-width: 992px) {
      left: 12%;
      top: 26%;
    }

    i {
      margin-left: 5px;
    }
  }
}

.inviteImageContainer {
  background: get($gray, 900);
  height: 700px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    height: 100%;
    width: 45%;
  }
}

.backgroundImageContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.backgroundImage {
  background-image: url('../../images/backgrounds/invite.jpg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.navTabs {
  background: #2d2e33;
  border: 0;
  list-style: none;
  margin: 0;
  opacity: 0.9;
  padding: 0;

  span {
    display: none;

    @media (min-width: 768px) {
      display: unset;
    }
  }

  ul {
    display: flex;
  }

  li {
    float: left;
    font-size: 1.4rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 5px;
    padding: 10px 10px 0 0;

    @media (min-width: 768px) {
      margin-right: 4%;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      background: #2d2e33;
      /* stylelint-disable-next-line declaration-property-value-disallowed-list */
      border: none;
      border-radius: 0;
      color: #f0f0f0;
      display: block;
      font-weight: 500;
      line-height: 1.4286;
      margin-right: 0;
    }

    img {
      display: inline-block;
      width: 12px;
    }
  }
}

.urlInvite {
  font-size: 1.6rem;

  span {
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.paneContent {
  overflow: hidden;
  padding: 14px 25px 25px;

  p {
    color: rgba(#fff, 0.3);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.referralLink {
  margin-bottom: 20px !important;
  margin-left: 0 !important;
  margin-top: 20px;
  width: 200px;
}

.paneContentDivider {
  padding: 0 0 10px;

  hr {
    border-top: 1px solid #4d4d4d;
    margin: 0;
  }
}

.emailInvite {
  color: #f0f0f0;
  margin-left: 15px;
}

.yourInvitations {
  background: #fff;
  height: 600px;
  margin: auto;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 6.5%;
  width: 100%;

  @media (min-width: 992px) {
    height: 100%;
    padding: 120px 0 0 100px;
    width: 55%;
  }
}

.inviteMetaDetails {
  color: get($gray, 300);
  font-size: 1.6rem;
  margin-top: 47px;

  .userCreditData {
    color: get($green, 600);
  }

  span {
    color: get($gray, 300);
  }

  img {
    border-radius: 50%;
    height: 40px;
    left: 0;
    min-width: 0;
    position: static;
    width: 40px;
  }

  .metaBlock {
    float: left;
    margin: 0 0 4px;
  }
}

.metaTitle {
  color: get($gray, 300);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 22px;
  text-transform: uppercase;
}

.inviteMetaDetails p a {
  color: get($gray, 300);
  font-size: 1.2rem;
  text-decoration: none;
}

.copiedToClipboardText {
  color: get($green, 600) !important;
  display: inline-block;
  font-size: 1.4rem;
  margin-top: 13px;
}

.creditStatistics {
  font-size: 3.6rem;
}

.yourInvitationsMetricsDivider {
  padding-bottom: 20px;
}

@media only screen and (min-width: 1500px) {
  .referralLink {
    font-size: 2rem;
    margin: 10px;
  }

  .navTabs li {
    margin-right: 6%;
  }
}
