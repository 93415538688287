.startMessage {
  display: flex;
  justify-content: start;
  margin-top: 50px;
}

.photoId {
  margin-right: 20px;
  min-width: 52px;
}
