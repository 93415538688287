@use 'scss/base/variables' as *;

.welcomeLetterModal {
  // Necessary to sit on top of the EM loader, can be removed when that's replaced
  z-index: 999999 !important;

  h4 {
    margin: 0 0 25px;
  }
}

.textBlueLight {
  margin-bottom: 10px;
}

.signatureBlock {
  float: right;
  margin-top: 20px;

  .signatureWrapper {
    height: 50px;
    margin-bottom: 15px;
    width: 160px;

    path {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: butt;
      stroke-miterlimit: 10;
      stroke-width: 10;

      &:nth-child(1) {
        animation: draw1 2s 1 ease-in;
        stroke-dasharray: 848;
      }

      &:nth-child(2) {
        animation: draw2 2s 1 linear;
        stroke-dasharray: 950;
      }

      &:nth-child(3) {
        animation: draw3 2s 1 linear;
        stroke-dasharray: 11;
      }

      &:nth-child(4) {
        animation: draw4 2s 1 ease-out;
        stroke-dasharray: 140;
      }
    }

    @keyframes draw1 {
      0% {
        stroke-dashoffset: 848;
      }

      35% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes draw2 {
      0%,
      40% {
        stroke-dashoffset: 950;
      }

      75% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes draw3 {
      0%,
      80% {
        stroke-dashoffset: 11;
      }

      85% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes draw4 {
      0%,
      90% {
        stroke-dashoffset: 140;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }
  }

  .bottom {
    .headshot {
      margin-right: 20px;
    }

    .nameTitle {
      color: get($gray, 500);
      float: right;
      line-height: 1.2;
      margin-top: 8px;
    }
  }
}
