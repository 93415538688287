@use 'scss/base/variables' as *;

.cards {
  border-left: 1px solid get($gray, 200);
  padding-left: 15px;

  @media (max-width: 991px) {
    border-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: -15px;
    padding-left: 0;
  }

  @media (max-width: 767px) {
    margin: -15px auto 0;
    max-width: 100%;
    width: 345px;
  }
}
