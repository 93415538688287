@use 'scss/base/variables' as *;

.accountsTable {
  td:last-child,
  th:last-child {
    text-align: right;
  }

  td,
  th {
    @media (max-width: 991px) {
      padding: 16px 10px;
    }
  }

  td {
    font-size: 1.6rem;
  }
}

.accountDetails {
  word-break: break-word;

  @media (max-width: 1199px) {
    max-width: 200px;
  }

  @media (max-width: 991px) {
    max-width: 150px;
  }
}

.statusWrap {
  min-width: 110px;
}

.verified {
  align-items: center;
  color: get($green, 600);
  display: flex;
  justify-content: flex-end;

  img {
    margin: -1px 5px 0 0;
  }
}

.pending,
.not_verified {
  color: get($poppy, 500);
}

.accountDescription {
  color: get($gray, 500);
  font-size: 1.2rem;
}

.verifyButton {
  width: max-content;
}

.accreditationSuccessModal {
  text-align: center;

  :global(.MuiBox-root) {
    top: 25%;
  }
}
