.cardHeading {
  align-items: center;
  display: flex;
  padding: 20px;

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 575px) {
    margin-bottom: 0;
  }

  h3 {
    margin: 0;
  }
}

.toggleButtonWrapper {
  margin-left: auto;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  button {
    @media (max-width: 767px) {
      padding: 12px 20px !important;
    }
  }
}
