@use 'scss/base/variables' as *;

.headerNav {
  margin-bottom: 40px;
}

.tabsCard {
  margin-bottom: -15px;

  @media (min-width: 576px) {
    padding-bottom: 0;
  }
}
