@use 'scss/base/variables' as *;

.topWrap {
  padding: 20px;
}

.top {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;

  @media (max-width: 575px) {
    display: block;
  }

  h3 {
    margin: 0 0 20px;
  }
}

.transferCount {
  display: inline-block;
  margin-right: 30px;
}

.textLink {
  font-size: 1.6rem;

  &.open {
    i {
      transform: rotate(90deg);
    }
  }

  i {
    font-size: 1.2rem;
    margin-left: 5px;
    transition: transform 0.2s ease-in-out;
  }
}

.exportButton {
  height: 44px;
  width: 200px;

  @media (max-width: 576px) {
    margin-top: 15px;
  }

  i {
    margin-right: 12px;
  }
}

.table {
  :global(.MuiTable-root) {
    th:nth-child(5),
    td:nth-child(5) {
      text-align: right;
    }
  }
}
