.investmentSection {
  margin-top: 0 !important;
  padding: 0;
}

.tooltip {
  @media (max-width: 767px) {
    display: none;
  }
}
