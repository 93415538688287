@use 'scss/base/variables' as *;

.continueButtonWrap {
  text-align: right;

  @media (max-width: 991px) {
    text-align: center;
  }
}

.footer {
  align-items: center;
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }

  button {
    margin-left: auto;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.disclaimer {
  padding-right: 50px;

  @media (max-width: 767px) {
    padding: 0 0 20px;
  }
}

.hr {
  border-color: get($gray, 200);
  margin: 40px 0;
}
