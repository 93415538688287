@use 'scss/base/variables' as *;

.cashflowSelect {
  display: inline-block;
  position: relative;

  &::after {
    color: get($gray, 500);
    content: '\f078';
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 5;
  }
}

.cashflowAmount {
  appearance: none;
  background: rgba(get($gray, 300), 0.2);
  border: 0;
  color: get($gray, 500);
  cursor: pointer;
  font-size: 1.8rem;
  padding-bottom: 12px;
  padding-left: 22px;
  padding-top: 12px;
  position: relative;
  width: 150px;
  z-index: 10;
}
