.stickySidebarWrap {
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  position: sticky;
  top: 20px;

  @media (max-width: 991px) {
    margin-bottom: 60px;
    max-height: none;
    overflow-y: initial;
    position: static;
  }
}
