@use 'scss/base/variables' as *;

.sideNav {
  width: 160px;
}

.top {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 2px 0 rgba(42, 43, 48, 0.15);
  padding: 15px;

  @media (max-width: 1199px) {
    display: none;
  }

  .navItem {
    font-size: 1.4rem;
    padding-left: 0;
  }
}

.bottom {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;

  &.invisible {
    opacity: 0;
    visibility: hidden;
  }
}

.navItem {
  color: $link-color;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.15;
  margin-bottom: 10px;
  padding-left: 13px;
  text-align: left;

  &.active {
    &::before {
      content: '«';
      font-size: 1.8rem;
      left: 0;
      line-height: 1;
      margin-right: 3px;
      position: absolute;
    }

    .navLabel {
      color: $link-color-hover;
      text-decoration: underline;
    }
  }

  &:hover {
    .navLabel {
      text-decoration: underline;
    }
  }

  .navLabel {
    display: block;
  }
}

.investButton {
  margin-top: 40px;
  padding: 10px !important;
  position: relative;
  width: 100%;

  &::before {
    background: #eee;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: -25px;
    width: 100%;
  }
}
