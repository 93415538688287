@use 'scss/base/variables' as *;

.card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 100px 15px;
  width: 100%;

  @media (max-width: 991px) {
    padding: 70px 30px;
  }

  @media (max-width: 767px) {
    padding: 40px 15px;
  }
}

.content {
  flex-shrink: 0;
  max-width: 100%;
  text-align: center;
  width: 410px;

  p {
    color: get($midnight, 300);
    margin-bottom: 0;
  }
}
