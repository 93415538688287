@use 'scss/base/variables' as *;

.offeringCard {
  background: #fff;
  border: 1px solid get($gray, 200);
  border-radius: $card-border-radius;
  display: flex;
  flex-flow: column wrap;
  height: calc(100% - 15px);
  margin-bottom: 15px;
  position: relative;

  @media (max-width: 767px) {
    margin: 0 auto 15px;
    max-width: 100%;
    width: 345px;
  }
}

.cardLink {
  margin-bottom: 25px;
}

.cardDisabled {
  cursor: default;

  .cardLink {
    pointer-events: none;
  }
}

.cardTop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 12px 0;
  position: relative;
  z-index: 1;
}

.pillar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  span {
    color: get($summit, 700);
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.pillarIcon {
  border-radius: 8px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  &.loading {
    background: get($gray, 200);
  }

  &.keep {
    background: get($keep, 900);
  }

  &.earn {
    background: get($earn, 900);

    svg {
      width: 14px;
    }
  }

  &.grow {
    background: get($grow, 700);
  }

  svg {
    width: 16px;

    path {
      fill: #fff;
    }
  }
}

.cardImage {
  background-color: #ddd;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  height: 190px;
  margin: 0 12px;
  position: relative;
  width: calc(100% - 24px);

  @media (min-width: 1200px) and (max-width: 1439px) {
    height: 155px;
  }

  &.loading {
    background:
      url('images/loader-white.svg'),
      linear-gradient(180deg, #ccc 0%, #ccc 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px, 100%;
  }
}

.cardAlert {
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 8px 10px;
  padding: 8px 8px 8px 32px;
  top: 8px;
  width: calc(100% - 16px);

  svg {
    width: 14px;
  }
}

.viewsCount {
  background: #fff;
  border-radius: 6px;
  color: get($gray, 900);
  float: right;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  margin: 10px 8px 0 0;
  padding: 4px 12px;
}

.headerBottom {
  bottom: 8px;
  left: 8px;
  line-height: 1;
  position: absolute;
  right: 8px;
}

.progress {
  align-items: center;
  background: #fff;
  border-radius: 6px;
  display: flex;
  height: 24px;
  padding: 2px;
  position: relative;

  .progressBar {
    background: get($bark, 900);
    border-radius: 6px;
    height: 100%;
  }

  svg {
    pointer-events: all;
  }
}

.progressLabel {
  align-items: center;
  color: get($gray, 900);
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 5px;
  padding: 0 6px;

  &.labelInside {
    position: absolute;
  }

  svg {
    height: 14px;
    width: 14px;
  }
}

.cardContent {
  padding: 15px 20px 0;
  position: relative;
  z-index: 1;

  .offeringTitle {
    color: get($gray, 900);
    margin: 5px 0 15px;
    min-height: 64px;

    @media (max-width: 991px) {
      min-height: 52px;
    }

    @media (max-width: 767px) {
      min-height: 0;
    }
  }
}

.metric {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: 400px) {
    font-size: 1.2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .metricLabel {
    color: get($midnight, 300);
  }

  .metricValue {
    background: get($gray, 100);
    border-radius: 6px;
    color: get($gray, 900);
    padding: 0 8px;
    text-align: right;
  }
}

.location {
  color: get($midnight, 300);
  font-size: 1.2rem;
  font-weight: 500;
}

.buttonWrap {
  margin-top: auto;
  padding: 0 20px 20px;

  .button {
    padding: 0 12px;

    @media (max-width: 330px) {
      padding: 0 10px;
    }
  }
}

.investorPacketLink {
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.loadingTimer {
  background: get($gray, 200);
  border-radius: 6px;
  height: 24px;
  width: 40%;
}

.loadingButton {
  border-radius: 12px;
  height: 44px;
  margin-top: 20px;
}

.highlighted {
  .cardLink {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-bottom: 12px;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .cardTop {
    width: 100%;
  }

  .cardImageWrap {
    width: 42%;

    @media (max-width: 1199px) {
      width: 40%;
    }

    @media (max-width: 767px) {
      height: 190px;
      width: auto;
    }
  }

  .cardImage {
    aspect-ratio: 1.71 / 1;
    height: auto;

    @media (max-width: 1199px) {
      min-height: 75%;
    }

    @media (max-width: 767px) {
      aspect-ratio: initial;
      height: 100%;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 12px;
    width: 58%;

    @media (max-width: 1439px) {
      gap: 12px;
    }

    @media (max-width: 1199px) {
      width: 60%;
    }

    @media (max-width: 767px) {
      gap: 20px;
      padding: 15px 20px 0;
      width: 100%;
    }

    .offeringTitle {
      margin-bottom: 0;
      min-height: 0;

      @media (max-width: 1439px) {
        margin-bottom: 5px;
      }

      @media (min-width: 991px) {
        font-size: 2.4rem;
        font-weight: 600;
      }
    }
  }

  .titleWrap {
    display: flex;
    gap: 16px;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      gap: 12px;
    }
  }

  .featuredLabel {
    margin: 10px 0 0 auto;

    @media (max-width: 767px) {
      margin: 0 auto 0 0;
    }
  }

  .metrics {
    display: flex;
    gap: 65px;

    @media (max-width: 1199px) {
      gap: 20px;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  .metric {
    display: block;

    @media (max-width: 767px) {
      display: flex;
    }
  }

  .metricValue {
    display: inline-block;
    font-size: 1.6rem;
    margin-top: 12px;
    text-align: left;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  .description {
    font-weight: 400;
    margin: 0;
  }

  .buttonWrap {
    min-width: 270px;
    padding: 0;
    width: 40%;

    @media (max-width: 767px) {
      min-width: 0;
      width: 100%;
    }
  }
}
