.icon {
  padding: 40px 0;
  position: relative;
}

.postCard {
  &:nth-child(1) {
    margin-top: 0;
  }
}
