.newItemButtonWrap {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.removeButton {
  margin-left: 15px;
}
