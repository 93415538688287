.diversification {
  @media (max-width: 991px) {
    margin-bottom: 0 !important;
  }

  :global(.recharts-responsive-container) {
    margin-top: 25px;

    @media (max-width: 767px) {
      margin: 0 auto;
      max-width: 100%;
      width: 300px !important;
    }
  }
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    display: block;
  }

  h3 {
    margin: 0 0 20px;
  }
}

.toggleButtonWrapper {
  button {
    @media (max-width: 767px) {
      padding: 12px 10px !important;
    }
  }
}
