.tabsList {
  margin: 40px 40px 0 20px;
}

.pillarIconLabel {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px auto;
  padding: 5px 0;
  padding-left: 35px;

  &.earn {
    background-image: url('../../../images/icons/earn.svg');
  }

  &.grow {
    background-image: url('../../../images/icons/grow.svg');
  }

  &.keep {
    background-image: url('../../../images/icons/keep.svg');
  }
}

.tabLink {
  &.tabLinkActive .pillarIconLabel {
    @media (max-width: 767px) {
      // Change to white when active to avoid the icon colors on top of blue
      filter: saturate(0%) brightness(200%);
    }
  }

  &:disabled .pillarIconLabel {
    filter: saturate(0%) brightness(100%);
  }
}
