@use 'scss/base/variables' as *;

.info-icon-margin-left {
  margin-left: 5px;
}

.tooltip-has-icon {
  max-width: 430px;
  padding: 0;

  .content {
    align-items: center;
    display: flex;
    min-height: 62px;
    padding-right: 80px;
  }

  .tooltip-icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;

    &.orange {
      background: get($poppy, 500);
    }

    &.yellow {
      background: get($yellow, 600);
    }

    &.green {
      background: get($green, 600);
    }

    i.fa {
      color: #fff;
      font-size: 4rem;
      line-height: 5.7;
    }
  }
}

.popover-content {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.7;

  a {
    color: #fff;
    text-decoration: underline;
  }
}
