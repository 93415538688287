@use 'scss/base/variables' as *;

// Global customizations for REUI card component

.border-top-card {
  border-top: 6px solid get($gray, 300);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
}
