.h6 {
  margin: 40px 0 20px;
}

.emailSent {
  min-height: 540px;
  padding-right: 50px;

  @media (max-width: 767px) {
    min-height: 0;
    padding: 0 0 50px;
  }

  h6 {
    margin: 70px 0 15px;
  }
}

.description {
  margin: 20px 0 35px;
}
