.row {
  align-items: stretch;
  min-height: calc(100vh - 220px);

  @media (max-width: 767px) {
    min-height: 0;
  }
}

.contentColumn {
  align-items: center;
  display: flex;

  @media (max-width: 1199px) {
    justify-content: center;
  }
}

.content {
  margin: 0 auto;
  max-width: 100%;
  width: 580px;

  @media (max-width: 1199px) {
    margin: 0;
  }
}

.skipLink {
  display: none;
  margin: 35px auto 0;
  width: fit-content;

  @media (max-width: 991px) {
    display: block;
  }
}
