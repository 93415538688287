@use 'scss/base/variables' as *;

.accountListItem {
  border-bottom: 1px solid #eee;
  padding: 25px 0 50px;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.tableCell {
  display: inline-block;
  padding-right: 80px;
  vertical-align: top;

  @media (max-width: 767px) {
    float: left;
    width: 50%;
    word-break: break-word;

    &:nth-child(odd) {
      padding: 0 10px 0 0;
    }

    &:nth-child(even) {
      padding: 0 0 0 10px;
    }
  }

  &.accountInfo {
    width: 250px;

    @media (max-width: 767px) {
      width: 50%;
    }
  }

  &.address {
    color: #979797;
  }
}

.updateButton {
  @media (max-width: 767px) {
    background: get($summit, 900);
    border-radius: 3px;
    color: #fff !important;
    float: none;
    font-size: 1.6rem;
    height: 50px;
    margin-top: 25px;
    padding: 15px 12px;
    text-decoration: none !important;
    transition: background-color 0.2s ease-in-out;
    width: 100%;

    &:hover {
      background-color: #038cca;
      text-decoration: none !important;
    }
  }
}

.accountList {
  margin: 30px 0;

  hr {
    margin: 0;
  }
}
