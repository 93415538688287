@use 'scss/base/variables' as *;

.createdInviteImg {
  background-color: rgb(188, 194, 197);
  border-radius: 50%;
  font-size: 1.4rem;
  height: 40px;
  padding-top: 12px;
  position: static;
  text-align: center;
  width: 40px;

  span {
    color: #fff !important;
    text-transform: uppercase;
  }
}

.inviteMetaDetails {
  color: get($gray, 300);
  font-size: 1.6rem;
  margin-top: 47px;

  img {
    border-radius: 50%;
    height: 40px;
    left: 0;
    min-width: 0;
    position: static;
    width: 40px;
  }

  span {
    color: get($gray, 300);
  }

  p a {
    color: get($gray, 300);
    font-size: 1.2rem;
    text-decoration: none;
  }
}

.metaBlock {
  float: left;
  margin: 0 0 4px;
}

.metaTitle {
  color: get($gray, 300);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 22px;
  text-transform: uppercase;
}

.userCreditData {
  color: get($green, 600);
}

.smallText {
  font-size: 1.2rem;
  margin-top: -10px;
}

.investing {
  margin-top: 11px;
}

.labelGreen {
  background-color: get($green, 600);
  color: #fff;
  font-weight: 700;
  padding: 5px;

  @media (min-width: 768px) {
    padding: 10px 12px 8px;
  }
}
