@use 'scss/base/variables' as *;

.transferDetails {
  padding: 15px 50px 0;

  @media (max-width: 767px) {
    padding: 15px 15px 0;
    width: 470px;
  }

  @media (max-width: 575px) {
    padding: 15px 0 0;
    width: calc(100vw - 87px);
  }

  h5 {
    margin: 0 0 20px;
  }
}

.textLabel {
  margin-bottom: 7px;
}

.value {
  margin-bottom: 25px;
}

.transactionsTable {
  border: 2px dashed get($gray, 200);
  border-radius: 0;
  padding: 5px 20px;

  :global(.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root) {
    border: 0;
    font-weight: 700;
  }

  :global(.MuiTableCell-root:last-child) {
    text-align: right;
  }
}

.transferDetailsHeading {
  margin-bottom: 20px;
}
