@use 'scss/base/variables' as *;

.signinContainer {
  margin: -60px 0 !important;
}

.signinPageWrapper {
  display: table;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  width: 100%;
}

.signinSection {
  background: get($gray, 100);
  height: 100%;
  vertical-align: middle;

  @media (max-width: 991px) {
    background-color: #fff;
    width: 100%;
  }
}

.loginButtons {
  font-size: 1.4rem;
  padding-bottom: 25px;
  padding-top: 25px;
}

.eqmLogo {
  img {
    margin: 60px 0;
    max-width: 100%;
    width: 270px;

    @media (max-width: 991px) {
      margin: 24px 0 0;
    }
  }
}

.loginForm {
  background-color: #fff;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  margin: 0 auto 40px;
  max-width: 400px;
  padding: 40px 40px 0;

  @media (max-width: 991px) {
    box-shadow: none;
    padding: 40px 15px 0;
  }

  h4.text-center {
    margin-bottom: 20px;
  }
}

.signinButton {
  width: 100%;
}

.cardFooter {
  background-color: rgba(248, 248, 249, 0.5);
  margin: 40px -40px 0;
  padding: 20px 40px;
  text-align: center;

  @media (max-width: 991px) {
    margin: 40px 0 0;
  }

  a {
    font-size: 1.4rem;
  }

  p {
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.account {
  vertical-align: super;
}

.signUpLink {
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

.icon {
  margin-left: 7px;
}

.errorMessage {
  margin-bottom: 20px;
  width: 100%;
}

.optSignin {
  @media (max-width: 991px) {
    text-align: center;
  }
}

.cbRememberMe {
  @media (max-width: 991px) {
    padding-top: 10px;
  }

  label {
    margin-left: 0;
  }
}

.forgot {
  @media (max-width: 991px) {
    display: block;
    padding-top: 10px;
  }

  a {
    float: right;
    margin-top: 6px;

    @media (max-width: 991px) {
      float: none;
    }
  }

  button.text-link {
    margin-top: 10px;
  }
}

.resendTimer {
  margin-top: 10px;
}

.loginPage #intercom-container {
  @media (max-width: 479px) {
    display: none;
  }
}
