@use 'scss/base/variables' as *;

.downloadHeading {
  @media (max-width: 479px) {
    display: none;
  }
}

.selectDoc input + label {
  margin-bottom: 14px;
}

.offeringName {
  color: get($gray, 900);
  font-size: 1.6rem;
  line-height: 1.2;

  @media (max-width: 1199px) {
    margin-bottom: 5px;
  }
}

.entityName {
  color: get($gray, 500);
  text-transform: uppercase;

  &:first-child td {
    @media (max-width: 479px) {
      display: none;
    }
  }
}

.fileIcon {
  @media (max-width: 479px) {
    display: none;
  }
}

.mobile {
  text-align: center;

  @media (min-width: 479px) {
    display: none;
  }
}

.error {
  position: relative;

  &::before {
    color: get($poppy, 500);
    content: '\f071';
    font-family: FontAwesome;
    left: -20px;
    line-height: 2.5;
    position: absolute;

    @media (max-width: 1199px) {
      line-height: 1.25;
      margin-right: 8px;
    }
  }
}

.card {
  margin-top: 0;
  padding: 30px 48px;

  @media (max-width: 479px) {
    padding: 15px !important;
  }
}

.taxSummaryInfo {
  border-radius: 3px;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5;
  margin: 0 0 10px 40px;
  padding: 11px 15px;
  vertical-align: top;

  @media (max-width: 1199px) {
    margin: 0 0 20px;
    padding: 0;
    text-align: left;
  }
}

.table {
  thead {
    th {
      padding: 18px 10px 18px 0;

      &:first-child {
        @media (max-width: 1199px) {
          display: none;
        }

        :global(#select) + label {
          margin: 0;
        }
      }
    }

    tr {
      td {
        &:first-child {
          @media (max-width: 1199px) {
            display: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid get($gray, 200);
        color: get($gray, 900);
        padding: 25px 25px 25px 0;
        vertical-align: bottom;

        &:first-child {
          @media (max-width: 1199px) {
            display: none;
          }
        }
      }
    }
  }
}
