@use 'scss/base/variables' as *;

.table {
  td {
    &:nth-child(3) {
      min-width: 142px;
    }

    &:nth-child(4) {
      min-width: 240px;
    }

    &:last-child {
      button {
        float: right;
      }
    }
  }
}

.offeringName {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  gap: 12px;

  a {
    svg {
      width: 14px;
    }
  }
}

.entityName {
  color: get($gray, 500);
  font-size: 1.2rem;
  text-transform: uppercase;
}
