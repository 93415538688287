@use 'scss/base/variables' as *;

.steps {
  display: flex;
  gap: 5px;
  margin-top: 8px;
  max-width: 160px;

  .step {
    background: get($summit, 200);
    border-radius: 6px;
    flex-grow: 1;
    height: 6px;

    &.complete {
      background: get($green, 600);
    }

    &.current {
      background: get($summit, 800);
    }
  }
}
