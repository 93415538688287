@use 'scss/base/variables' as *;

.header {
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  display: flex;
  min-height: 500px;
  position: relative;

  &.leftGradient {
    &::before {
      background: linear-gradient(
        90deg,
        rgba(3, 19, 60, 1) 0%,
        rgba(3, 19, 60, 0.7) 59.9%,
        rgba(3, 19, 60, 0) 100%
      );
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 80%;

      @media (max-width: 991px) {
        width: 70%;
      }
    }
  }

  @media (max-width: 991px) {
    min-height: 400px;
  }

  @media (max-width: 767px) {
    min-height: 350px;
  }
}

.progressLabel {
  color: rgba(#fff, 0.8);
  float: right;
  margin-bottom: 0;
  margin-bottom: 17px;
  margin-top: 14px;
}

.intro {
  padding: 40px 0;
  position: relative;
  width: 100%;

  @media (max-width: 575px) {
    padding: 20px 0;
  }

  &::before {
    background: linear-gradient(
      -180deg,
      rgba(3, 19, 60, 0) 0%,
      rgba(3, 19, 60, 0.4) 120px,
      rgba(3, 19, 60, 0.9) 100%
    );
    bottom: 0;
    content: '';
    display: block;
    height: calc(100% + 100px);
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.subHeading,
.subCopy {
  color: #fff;
  max-width: 100%;
  width: 450px;
}

.subHeading {
  font-size: 2rem;
  margin: 0 0 20px;

  @media (max-width: 575px) {
    font-size: 1.8rem;
  }
}

.subCopy {
  margin: 0;

  @media (max-width: 575px) {
    font-size: 1.2rem;
  }
}

.subImage {
  display: block;
  margin: 50px 0 20px;
  max-height: 130px;
  max-width: 150px;

  @media (max-width: 991px) {
    margin-top: 40px;
    max-height: 100px;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    max-height: 80px;
  }

  @media (max-width: 575px) {
    max-height: 70px;
  }
}

.title {
  color: #fff;
  display: inline-block;
  font-size: 4rem;
  margin: 0;
  padding-right: 20px;

  @media (max-width: 767px) {
    font-size: 3.2rem;
  }

  @media (max-width: 575px) {
    font-size: 2.6rem;
  }
}

.progress {
  background: rgba(#fff, 0.3);
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  height: 4px;
  margin-bottom: 0;
  overflow: hidden;
  width: 100%;
}

.progressBar {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  height: 100%;
  transition: width 0.6s ease;
}

.galleryModal {
  :global(.MuiBox-root) {
    left: 0;
    max-height: 100%;
    max-width: none;
    padding-top: 60px;
    top: 0;
    transform: none;
    width: 100%;

    @media (max-width: 991px) {
      padding-top: 55px;
    }

    button {
      color: #fff;
    }
  }

  :global(.MuiPaper-root) {
    background: none;
    border-radius: 0;
    box-shadow: none;
  }
}
