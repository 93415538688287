.button-fixed-width {
  max-width: 100%;
  width: 200px;
}

.arrowBackButton {
  padding: 10px !important;
  width: 44px;

  &:hover,
  &:focus,
  &:active {
    svg path {
      stroke: #fff;
    }
  }

  svg {
    width: 16px;
  }
}
