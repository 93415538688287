#unsupported-browser-page {
  text-align: center;

  header {
    display: inline-block;
    width: 90%;
  }

  .logo {
    background-image: url('https://app.equitymultiple.com/assets/signin_logo.svg');
    background-repeat: no-repeat;
    float: left;
    height: 88px;
    margin: 20px 10px 10px 45px;
    width: 88px;
  }

  .text-header {
    font-size: 2.5rem;
  }

  .icons {
    display: inline-block;
    font-family: Verdana, sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    width: 33%;
  }

  .browser {
    display: inline-block;
    height: 160px;
    text-align: center;
    vertical-align: middle;
    width: 49%;
  }

  .options {
    margin-left: 5%;
    text-align: left;
    width: 90%;
  }

  .option {
    margin-left: 5%;
    text-align: left;
  }

  .text {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;
    vertical-align: top;
    width: 38%;
  }

  .download-msg {
    padding-top: 20px;
  }

  .same-line {
    display: inline-block;
    padding-top: 50px;
  }

  @media (max-width: 865px) {
    .text-header {
      padding-top: 0;
    }

    .text {
      padding: 0;
      text-align: center;
      width: 65%;
    }

    .icons {
      display: flex;
      padding: 15px 0;
      vertical-align: top;
      width: 100%;
    }

    .same-line {
      padding-top: 0;
    }
  }

  @media (max-width: 768px) {
    .text {
      width: 90%;
    }

    .icons {
      display: inline-block;
    }

    .logo {
      float: none;
      margin: 0 auto;
      margin-top: 23px;
    }
  }
}
