@use 'scss/base/variables' as *;

.infoIconWrap {
  align-items: flex-start;
  display: flex;
}

.infoIconMarginLeft {
  margin-top: -3px;
}

.th {
  flex-basis: 0;
  text-align: left;
}

.rowLink {
  &:hover {
    .offeringName,
    i {
      color: $link-color;
    }
  }
}

.fundInvested {
  display: flex;
  position: relative;
}

.fundInvestedLabel {
  clear: both;
  color: get($gray, 300);
  float: left;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 3px;
  padding-right: 10px;
  text-transform: uppercase;

  &.contributions {
    float: none;
    margin-bottom: 5px;
  }
}

.fundInvestedValue {
  clear: both;
  float: left;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 7px;

  &.marginNone {
    margin-bottom: 0;
  }
}

.operator {
  bottom: 2px;
  float: right;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  padding-left: 10px;
  position: relative;
}

.amounts {
  border-right: 1px solid #666;
  margin-right: 15px;
  padding-right: 15px;
}

.contributionDates {
  font-size: 1.2rem;
  line-height: 1;
}

.dateGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.date {
  font-weight: 400;
  padding-right: 12px;
}

.amount {
  font-weight: 700;
}

.fundInvestedTooltip {
  font-family: Larsseit, sans-serif;
}

.table {
  :global(.MuiTableCell-root) {
    vertical-align: top;

    &:first-child {
      min-width: 200px;

      @media (max-width: 575px) {
        min-width: none;
      }
    }

    &:last-child {
      padding-left: 0;
      vertical-align: middle;
    }

    .paymentStatus {
      display: flex;
      margin-bottom: 10px;
    }

    .paymentDetails {
      color: get($gray, 500);
      padding-top: 10px;
    }

    .offeringName,
    i.fa {
      transition: color 0.2s ease-in-out;
    }
  }
}

.secondaryText {
  color: get($midnight, 200);
  font-size: 1.2rem;
  font-weight: 500;
}
