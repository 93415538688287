@use 'scss/base/variables' as *;

.articleCard {
  background: #fff;
  border: 2px solid transparent;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  display: block;
  min-height: 250px;
  padding: 20px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;

  &.loading {
    pointer-events: none;
  }

  &:not(.loading) {
    &:hover {
      border-color: get($gray, 200);
      text-decoration: none;
    }

    &:focus {
      border-color: get($gray, 200);
      text-decoration: none;
    }
  }
}

.tag {
  color: get($gray, 600);
  font-weight: 500;
  line-height: 1;
}

.arrow {
  color: $link-color;
  font-size: 3rem;
}

.cardHeading {
  margin: 20px 0 8px;

  @media (max-width: 1199px) {
    font-size: 2.2rem;
  }

  @media (max-width: 767px) {
    font-size: 2rem;
  }

  span {
    display: block;
    width: 100%;
  }
}
