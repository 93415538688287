@use 'scss/base/variables' as *;

.quoteText {
  color: get($moon, 900);
  font-family: 'Financier Display', serif;
  font-size: 2.8rem;
  margin: 20px 0 40px;
}

.phone {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 300px;

  @media (max-width: 1199px) {
    width: 200px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}
