@use 'scss/base/variables' as *;

.omDetails {
  margin: 0 auto;
  max-width: 100%;

  @media (max-width: 767px) {
    width: 345px;
  }

  @media (max-width: 576px) {
    width: 290px;
  }
}

.textLabel {
  margin-bottom: 15px;
  text-align: right;
}

.metric {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.name {
  color: get($gray, 500);
}

.value {
  font-weight: 500;
}

.disclaimer {
  margin: 10px 0 0 30px;

  @media (max-width: 991px) {
    margin-left: 20px;
  }
}
