@use 'scss/base/variables' as *;

.mainNav {
  background: get($summit, 900);
  padding: 3px 30px;
  position: relative;
  z-index: 1000;

  @media (max-width: 1199px) {
    padding: 3px 15px;
  }
}

.navContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.headerLogo {
  display: flex;

  img {
    width: 210px;
  }
}

.desktopMenu {
  margin: 0 auto 0 60px;

  @media (max-width: 1199px) {
    margin-left: 40px;
  }

  @media (max-width: 991px) {
    display: none;
  }

  a {
    color: #fff;
    display: inline-block;
    font-size: 1.6rem;
    margin-right: 30px;
    padding: 16px 5px;
    text-decoration: none;

    @media (max-width: 1199px) {
      margin-right: 15px;
    }

    &:hover {
      color: rgba(#fff, 0.4);
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }

    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }

  .emDropdownToggle {
    margin-right: 30px;

    @media (max-width: 1199px) {
      margin-right: 15px;
    }
  }
}

.mobileMenu {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 50px 0 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  width: 375px;
  z-index: 1001;

  @media (min-width: 992px) {
    display: none;
  }

  &.open {
    transform: translateX(0);
  }

  a,
  .subMenuToggle,
  .disabledLink {
    color: $link-color;
    display: block;
    font-size: 1.6rem;
    padding: 13px 25px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      background-color: get($gray, 100);
      color: $link-color-hover;
    }

    &.logOutButton {
      display: flex;
      margin: 0 25px;
    }

    &.active {
      color: $link-color-hover;
      font-weight: 600;
    }
  }

  .subMenuToggle {
    text-align: left;
    width: 100%;
  }

  .closeButton {
    background-image: url('images/icons/close-blue.svg?url');
    display: block;
    height: 17px;
    position: absolute;
    right: 25px;
    top: 20px;
    width: 17px;
  }

  .backButton {
    color: $link-color;
    display: block;
    font-size: 1.6rem;
    left: 25px;
    opacity: 0;
    padding-left: 23px;
    pointer-events: none;
    position: absolute;
    top: 20px;
    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;
      pointer-events: initial;
    }

    &::before {
      background-image: url('images/icons/arrow-right-blue.svg?url');
      border: 0;
      content: '';
      display: block;
      height: 17px;
      left: 0;
      position: absolute;
      top: 1px;
      transform: scaleX(-1);
      width: 12px;
    }
  }

  .caretRight {
    background-image: url('images/icons/arrow-right-blue.svg?url');
    display: block;
    height: 17px;
    position: absolute;
    right: 41px;
    top: 15px;
    width: 12px;
  }

  .border {
    border-bottom: 1px solid get($gray, 300);

    &.bottomBorder {
      margin: 16px 25px 22px;
    }
  }

  .top {
    padding: 4px 0 16px;

    .subMenuToggle {
      line-height: 1;

      .caretRight {
        top: 20px;
      }
    }

    .name {
      font-size: 2rem;
      margin-bottom: 4px;

      &.noLink {
        line-height: 1;
        padding: 0 25px;
      }
    }

    .linkText {
      font-size: 1.4rem;
    }

    .border {
      margin: 26px 25px 0;
    }
  }

  .menu {
    position: relative;
    transition: transform 0.25s ease-in-out;

    &.subMenuOpen {
      transform: translateX(-100%);
    }
  }

  .subMenu {
    left: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.mobileMenuToggle {
  display: none;

  // Make the clickable area larger without affecting the right margin
  margin: 13px -5px 13px 0;
  padding: 5px;
  transition: opacity 0.2s ease-in-out;

  @media (max-width: 991px) {
    display: block;
  }

  &:hover {
    opacity: 0.6;
  }

  .iconBar {
    background: #fff;
    border-radius: 1px;
    display: block;
    height: 3px;
    width: 26px;
  }

  .iconBar + .iconBar {
    margin-top: 5px;
  }
}

button.emDropdownToggle {
  position: relative;

  &::after {
    background: transparent;
    content: '';
    height: 3px;
    left: 5px;
    position: absolute;
    right: 5px;
    top: calc(100% - 0px);
    transition: background 0.2s ease-in-out;
  }

  &.userDropdown {
    @media (max-width: 991px) {
      display: none;
    }

    .emDropdownMenu {
      left: initial;
      min-width: 150px;
      right: -30px;
    }
  }

  &.open {
    &::after {
      background: get($summit, 400);
    }
  }

  .toggleButton {
    color: #fff;
    display: inline-block;
    font-size: 1.6rem;
    padding: 16px 5px;
    transition: color 0.2s ease-in-out;

    &:focus {
      color: #fff;
      text-decoration: none;
    }

    &:hover {
      color: rgba(#fff, 0.4);
      text-decoration: none;
    }
  }

  .caret {
    margin-left: 6px;
  }

  .emDropdownMenu {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(42, 43, 48, 0.15);
    display: none;
    left: -19px;
    min-width: 180px;
    padding: 10px 0;
    position: absolute;
    text-align: left;
    top: calc(100% + 3px);

    a,
    .disabledLink {
      color: get($gray, 600);
      display: block;
      font-size: 1.4rem;
      margin: 0;
      padding: 11px 24px;

      &:hover,
      &:focus,
      &:active {
        color: $link-color;
        text-decoration: none;
      }

      &.active {
        color: $link-color;

        .linkText::after {
          @media (min-width: 992px) {
            background: $link-color;
            transition: background 0.2s ease-in-out;
          }
        }
      }

      .linkText {
        position: relative;

        &::after {
          @media (min-width: 992px) {
            background: transparent;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            right: 0;
            top: calc(100% + 2px);
          }
        }
      }
    }

    &.open {
      display: block;
    }
  }
}

.disabledLink {
  align-items: center;
  color: get($midnight, 200) !important;

  &:hover {
    background-color: initial !important;
    cursor: default !important;
  }

  > span {
    align-items: center;
    display: flex;
  }

  .infoIcon {
    display: flex;
    margin-left: 5px;

    &:hover {
      cursor: pointer !important;
    }
  }
}

.tooltip {
  z-index: 9999;
}
