@use 'scss/base/variables' as *;

.tooltipWrap {
  button:hover,
  button:focus {
    text-decoration: none !important;
  }
}

.navToggle {
  display: flex;
  font-size: 2rem;

  &:hover .iconBar span {
    background: $link-color-hover;
  }
}

.iconBar {
  display: inline-block;
  margin-right: 10px;
  padding: 5px;

  span {
    background: $link-color;
    display: block;
    height: 2px;
    width: 20px;

    + span {
      margin-top: 4px;
    }
  }
}

.toggleText {
  @media (max-width: 575px) {
    display: none;
  }
}

.navItem {
  + .navItem {
    margin-top: 12px;
  }

  button {
    color: $link-color;
    text-align: left;
  }
}

.tooltip {
  left: 0 !important;
  min-width: 200px !important;
  transform: none !important;

  &::before {
    left: 10px !important;
  }

  &::after {
    left: 9px !important;
  }
}
